import React, { useState } from 'react';
//import dadesbancaries from '../data/dadesbancaries.json'
//import assignatures from '../data/assignatures.json'
import llista_bonificacions from '../../../data/llista_bonificacions.json'
import config from '../../../config';
//import llistaExemptesPagament from '../../../data/llista_exemptes_pagament.cavallbernat.json'
//import llistaExemptesPagamentAmbLlibres from '../../../data/llista_exemptes_pagament_amb_llibres.cavallbernat.json'

const dadesbancaries = require('../../../data/' + config.dadesbancaries)
const assignatures = require('../../../data/' + config.assignatures)


const exemptPagament = (iddocument,becat) => {
  if( becat === "B") return true;
  //if(!iddocument?.length) return false;
  //return llistaExemptesPagament.filter( id => id === iddocument ).length > 0
  return false
}

const exemptPagamentAmbLlibres = (iddocument,becat) => {
  if( becat === "L") return true;
  //if(!iddocument?.length) return false;
  //return llistaExemptesPagamentAmbLlibres.filter( id => id === iddocument ).length > 0
  return false
}


export function calculQuota(curs, assignaturesTriades, dataNaixement, repetidor, setRepetidor, materials, setMaterials, setNivellMax, bonificacio, torn, iddocument, becat ) {

let quota = {
             matricula: 0,
             asseguranca: 0,
             material: 0,
             formacio: 0,
             bonificacio: 0,
             moduls: 0,
             total: 0,
             dadesbancaries: dadesbancaries }

/* controls que impedeixen que es calcul la quota sense tenir les dades corresponents necessaries per al càlcul */
if(dataNaixement === undefined) return quota
if(curs === undefined) return quota
if(curs?.id === undefined) return quota

// busquem nivell maxim de assignatura (només cal que en tinguin una de 2n per ser matricula de 2n curs)
let nivellMax = 0
const llistaAssignatures = assignatures[curs.id]
  llistaAssignatures.map((ll) => {
    ll.subjects.map((s) => { 

      if(assignaturesTriades.includes(s.id))
      {
          if(s.level > nivellMax)
          {
              nivellMax = s.level
          }
        }
    }
    )
  })

  setNivellMax(nivellMax)

  //if(nivellMax === 2 && !props.nouAlumne ) setRepetidor(2)



let temps= new Date()
temps.setFullYear(new Date().getFullYear() - 28)

  quota.menor28 = dataNaixement.getTime() >= temps.getTime() ? true : false

  const quotaAsseguranca = (menor28) => {

    if (menor28) {
      return 1.15
    }
      
    return 0
  }

  const quotaMaterial = (curs) => {

    if (curs.material === undefined ) return 0

    if(materials.length === 0 )  // inicial
    {
      let mats = []

      curs.material.map((m) => 
      { 
            return mats.push({...m,checked: m.check })
      })

      //console.log("MATS",mats)

      setMaterials(mats)
    }


    let totalMaterials =  0

      materials.map((m) => 
      { 

          if(m.checked) totalMaterials += m.preu
            
      })

      
    return totalMaterials
  }

  const quotaBonificacio = (matricula,bonificacio) => {

    const bon = llista_bonificacions.filter(b => b.id === bonificacio ).pop()

    if (bon) {

      switch (bon.descompte) {
        case '50%': bonificacio = matricula / 2; break;
        case '100%': bonificacio = matricula; break;
        default: bonificacio = 0;
      }

    }

    return bonificacio
  }


const quotaCFGM = (quota, assignaturesTriades ) => {

//const preuUF = 25
const preuFCT = 10

  // assegurança + despeses
  quota.asseguranca = quotaAsseguranca(quota.menor28) 
  quota.total += quota.asseguranca

  if (assignaturesTriades.length === 1 && /^FCT.2/.test(assignaturesTriades[0])) {

    quota.moduls = preuFCT;

  } else {

    const llistaPreus = []
    const llistaAssignaturesPrimer = []
    const llistaAssignaturesSegon = []
    const llistaBlocsPrimer = []
    const llistaBlocsSegon = []

    assignatures[curs.id].map((bloc) => {
      //console.log(bloc)

      bloc.subjects.map(
        (assignatura) => {

          llistaPreus[assignatura.id] = assignatura.preu
          //console.log("BL L",assignatura.id,assignaturesTriades,assignaturesTriades.includes(assignatura.id));

          if (assignatura.level === 1) {

            llistaAssignaturesPrimer.push(assignatura.id)

            // les FCT no compten com a modul
            if (!/^FCT/.test(assignatura.id)) {
              if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsPrimer[bloc.id] = llistaBlocsPrimer[bloc.id] == undefined ? 1 : llistaBlocsPrimer[bloc.id] + 1 }
            }

          }

          if (assignatura.level === 2) {

            llistaAssignaturesSegon.push(assignatura.id)

            if (!/^FCT/.test(assignatura.id)) {
              if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsSegon[bloc.id] = llistaBlocsSegon[bloc.id] == undefined ? 1 : llistaBlocsSegon[bloc.id] + 1 }
            }
          }

        }
      )
    }
    )

    /*
    //console.log("Blocs",Object.keys(llistaBlocs).length,llistaBlocs)
    //console.log(llistaAssignaturesPrimer)

    matricula_parcial_1r = true;
    //matricula_parcial_1r_count = 0;

    if (llistaAssignaturesPrimer.length > 0) {
      let copiaAssignatures1r = [...llistaAssignaturesPrimer]

      matricula_parcial_1r = copiaAssignatures1r.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      //matricula_parcial_1r_count = copiaAssignatures1r.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    matricula_parcial_2n = true;
    //matricula_parcial_2n_count = 0;

    if (llistaAssignaturesSegon.length > 0) {
      let copiaAssignatures2n = [...llistaAssignaturesSegon]

      matricula_parcial_2n = copiaAssignatures2n.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      //matricula_parcial_2n_count = copiaAssignatures2n.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    if (matricula_parcial_1r && matricula_parcial_2n) {
      // Les FCT de primer no computen com a assignatura a pagar 
      assignaturesTriades.filter((ass) => !/^FCT/.test(ass)).map((ass) => {  quota.material += llistaPreus[ass] })
      assignaturesTriades.filter((ass) => !/^FCT/.test(ass)).map((ass) => {  quota.matricula += preuUF })

      quota.matricula += ( preuFCT * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)

    }
    else {
      quota.matricula = preuMatricula
    }
      */


    /* REFEM CALCUL */

    quota.matricula = 0
    assignaturesTriades.filter((ass) => {  quota.matricula += llistaPreus[ass] })

   // assignaturesTriades.filter((ass) => !/^FCT/.test(ass)).map((ass) => {  quota.matricula += preuUF })
   //quota.matricula += ( preuFCT * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)

    //DEBUG console.log("CALCUL", quota.matricula,quota.material)

    /* moduls */
    quota.moduls = 0

    /*
    if (matricula_parcial_1r && matricula_parcial_2n) {
      // Les FCT de primer no computen com a assignatura a pagar pero les de segon en matricula parcial si
      quota.moduls = ( preuFCT * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)
    }

    //console.log("PARCIAL 1",matricula_parcial_1r)
    //console.log("PARCIAL 2",matricula_parcial_2n)

    // preu 1r
    if (matricula_parcial_1r) {
      quota.moduls += preuModuls1 * Object.keys(llistaBlocsPrimer).length
    }

    // preu 2n
    if (matricula_parcial_2n) {
      quota.moduls += preuModuls2 * Object.keys(llistaBlocsSegon).length
    }
    */


  }

  quota.total += quota.moduls + quota.matricula


  if (assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0])) {
    //nomes FCT no paguen formacio ni tampoc tenen material
    quota.material = 0
  }
  else {
    quota.material += quotaMaterial(curs)
  }
  quota.total += quota.material

  // total
  return quota;
}


const quotaCFGS = (quota, assignaturesTriades, formacio, preuModuls1, preuModuls2) => {

  const preuFCT = 25
  const preuFCTModul = 10
  const preuMatricula = 360
  const preuUF = 25

  //formacio = formacio === undefined ? 60 : formacio

  // assegurança
  quota.asseguranca = quotaAsseguranca(quota.menor28)
  quota.total += quota.asseguranca

  if (assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0])) {

    quota.matricula = preuFCT 
    //quota.moduls = preuFCTModul

  } else {

    const llistaAssignaturesPrimer = []
    const llistaAssignaturesSegon = []
    const llistaBlocsPrimer = []
    const llistaBlocsSegon = []
    const llistaPreus= []

    assignatures[curs.id].map((bloc) => {
      //console.log(bloc)

      bloc.subjects.map(
        (assignatura) => {

            llistaPreus[assignatura.id] = assignatura.preu

          //console.log("BL L",assignatura.id,assignaturesTriades,assignaturesTriades.includes(assignatura.id));

          if (assignatura.level === 1 && !/^FCT/.test(assignatura.id)) {
            llistaAssignaturesPrimer.push(assignatura.id)
            if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsPrimer[bloc.id] = llistaBlocsPrimer[bloc.id] == undefined ? 1 : llistaBlocsPrimer[bloc.id] + 1 }
          }
          if (assignatura.level === 2 && !/^FCT/.test(assignatura.id)) {
            llistaAssignaturesSegon.push(assignatura.id)
            if (assignaturesTriades.includes(assignatura.id)) { llistaBlocsSegon[bloc.id] = llistaBlocsSegon[bloc.id] == undefined ? 1 : llistaBlocsSegon[bloc.id] + 1 }
          }
        }
      )
    }
    )

    matricula_parcial_1r = true;
    let matricula_parcial_1r_count = 0;

    if (llistaAssignaturesPrimer.length > 0) {
      let copiaAssignatures1r = [...llistaAssignaturesPrimer]

      matricula_parcial_1r = copiaAssignatures1r.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      matricula_parcial_1r_count = copiaAssignatures1r.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    matricula_parcial_2n = true;
    let matricula_parcial_2n_count = 0;

    if (llistaAssignaturesSegon.length > 0) {
      let copiaAssignatures2n = [...llistaAssignaturesSegon]

      matricula_parcial_2n = copiaAssignatures2n.filter((assignatura) => !assignaturesTriades.includes(assignatura)).length === 0 ? false : true
      matricula_parcial_2n_count = copiaAssignatures2n.filter( (assignatura) =>  assignaturesTriades.includes(assignatura) ).length
    }


    //console.log("PARCIAL 1",matricula_parcial_1r,matricula_parcial_1r_count)
    //console.log("PARCIAL 2",matricula_parcial_2n,matricula_parcial_2n_count)



    if (matricula_parcial_1r && matricula_parcial_2n) {
      // Les FCT de primer no computen com a assignatura a pagar 
      quota.matricula = (!matricula_parcial_1r || !matricula_parcial_2n) ? 
                                  preuMatricula 
                                  : 
                                  (preuUF * assignaturesTriades.filter((ass) => !/^FCT/.test(ass)).length) + ( preuFCT * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)

    }
    else {
      quota.matricula = preuMatricula
    }

    if (quota.matricula > preuMatricula) quota.matricula = preuMatricula



    /* moduls */
    quota.moduls = 0

    // CALCUL MATERIAL ASSIGNATURES TRIADES
    assignaturesTriades.filter((ass) => { quota.moduls += llistaPreus[ass] })

    /*
    if (matricula_parcial_1r) {
      quota.moduls += preuModuls1 * Object.keys(llistaBlocsPrimer).length
    }

    if (matricula_parcial_2n) {
      quota.moduls += preuModuls2 * Object.keys(llistaBlocsSegon).length + ( preuFCTModul * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)

      //TEMP quota.moduls += ( preuFCTModul * assignaturesTriades.filter((ass) => /^FCT.2/.test(ass)).length)

    }
    */

  }


  // bonificacio
  if (curs.bonificacions === true) {
    quota.bonificacio = quotaBonificacio(quota.matricula, bonificacio)
  }

  // sumen al total despres de bonificacions
  quota.total += (quota.matricula - quota.bonificacio + quota.moduls)


  if (assignaturesTriades.length === 1 && /^FCT/.test(assignaturesTriades[0])) {
    //nomes FCT no paguen formacio ni tampoc tenen material

    //despeses de formació
    quota.formacio = 0
    quota.total += quota.formacio

    quota.material = 0
    quota.total += quota.material
  }
  else {
    //despeses de formació
    quota.formacio = formacio
    quota.total += quota.formacio

    quota.material = quotaMaterial(curs)

    quota.total += quota.material
  }

  return quota;
}


// iniciem a 'null'
let formacio = undefined
let llistaAssignaturesPrimer =  []
let llistaAssignaturesSegon =  []
let matricula_parcial_1r = true;
let matricula_parcial_2n = true;
//let matricula_parcial_1r_count = 0;
//let matricula_parcial_2n_count = 0;

quota.asseguranca = 0;
quota.material = 0;
quota.total = 0;

switch(curs.id)
{
     // ESO 1 i 2
     case "ESOLOE01": 
     case "ESOLOE02": 
     // ESO 3 i 4
     case "ESOLOE03": 
     case "ESOLOE04": 
                        // assegurança
                        //quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.asseguranca = 0
                        quota.total += quota.asseguranca

                        quota.matricula = 0
                        quota.total += quota.matricula

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material

                        // total
                        return quota;

     // BATX 1 i 2
    case "BATXLOE2000": 
    case "BATXLOE3000": 
    case "BATXLOE20001": 
    case "BATXLOE20002": 
    case "BATXLOE30001": 
    case "BATXLOE30002": 
                        // assegurança
                        //quota.asseguranca = quotaAsseguranca(quota.menor28)
                        quota.asseguranca = 0
                        quota.total += quota.asseguranca

                        quota.matricula = 0
                        quota.total += quota.matricula

                        // material
                        quota.material = quotaMaterial(curs)
                        quota.total += quota.material
                        // total
                        return quota;

  /*
  { CFPMCM20 CFGM Comercialització de productes alimentaris
  { CFPMIA60 CFGM Forneria, pastisseria i confiteria
  { CFPMHT10 CFGM Cuina i gastronomia
  { CFPMHT30 CFGM Serveis en restauració

  { CFPSHTA0 CFGS Agències de viatges i gestió d'esdeveniments
  { CFPSHTF0 CFGS Guia, informació i assistència turístiques/Agències de viatges i gestió d'esdeveniments
  { CFPSHTE0 CFGS Direcció de serveis en restauració
  { CFPSHTD0 CFGS Direcció de cuina
  */

    // MITJOS

     //CFGM Comercialització de productes alimentaris
  case "CFPMCM20N": 
  case "CFPMCM20": 
    return quotaCFGM(quota, assignaturesTriades)

  // CFGM Forneria, pastisseria i confiteria
  case "CFPMIA60N": 
  case "CFPMIA60": 
    return quotaCFGM(quota, assignaturesTriades)

  // CFGM Cuina i gastronomia
  case "CFPMHT10N":
  case "CFPMHT10":
    return quotaCFGM(quota, assignaturesTriades)

  // CFGM Serveis en restauració
  case "CFPMHT30N":
  case "CFPMHT30":
    return quotaCFGM(quota, assignaturesTriades )


    // SUPERIOR

  // CFPSHTA0 CFGS Agències de viatges i gestió d'esdeveniments
  case "CFPSHTA0":
    return quotaCFGS(quota, assignaturesTriades, 0, 12, 12)

  // CFGS Guia, informació i assistència turístiques/Agències de viatges i gestió d'esdeveniments
  case "CFPSHTF0":
  case "CFPSHTF0N":
    return quotaCFGS(quota, assignaturesTriades, 0, 19, 19)

  // CFGS Direcció de serveis en restauració
  case "CFPSHTE0":
  case "CFPSHTE0N":
    return quotaCFGS(quota, assignaturesTriades, 0, 26, 26)

  // CFGS Direcció de cuina
  case "CFPSHTD0":
  case "CFPSHTD0N":
    return quotaCFGS(quota, assignaturesTriades, 0, 12, 12)


  default: return quota;
      
}

}


/*****************************************
 * 
 * MostraQuota
 * 
 * 
 * 
 */

export const MostraQuota = (props)  => {

let quota = props.quota
const [bonificacio, setBonificacio] = useState([]);

const handleChangeRepetidor = (v) => {
  props.setRepetidor(v)

  //checkMaterials()
}


const canviaPreuMaterial = (id,preu) => {

let mat = [...props.materials]

  mat.filter(item => item.id === id).map( (m) => m.preu = preu )

  props.setMaterials(mat)

}

const canviaTxtMaterial = (id,txt) => {

let mat = [...props.materials]

  mat.filter(item => item.id === id).map( (m) => m.txt = txt )

  props.setMaterials(mat)

}


const handleChangeMaterial = (t) => {

//console.log("MAT0",t)

let mat = [...props.materials]

//console.log("MAT1",mat)

  mat.filter(item => item.id === t).map( (i) => i.checked = !i.checked )

//console.log("MAT2",mat)

  props.setMaterials(mat)

}

const isMaterialChecked = (id) => {

let mat = props.materials.filter(item => item.id === id).pop()

if(mat === undefined ) return false
return mat.checked
}


const handleChangeBonificacions = (t) => {
  //console.log(t)
  props.setBonificacions(t)
  setBonificacio(t)
}

const handleChangeTerminis = (t) => {
  props.setDosTerminis(t)
}


// calcul quotes  / terminis
//console.log("2T", props.dosTerminis);
if (props.dosTerminis) {

  switch(props.cicle)
  {

    // Taxes 1r i resta 2n
    case "CFGS": 
      quota.termini1 =  quota.matricula - quota.bonificacio
      quota.termini2 =  quota.total - quota.termini1
        break

    // 50%
    default:
    case "CFGM": 
        quota.termini1 =  quota.total / 2
        quota.termini2 =  quota.total - quota.termini1
        break
  }

}
else {
  quota.termini1 = undefined
  quota.termini2 = undefined
}

let enguany = new Date().getFullYear()
let anyVinent = enguany + 1
anyVinent = anyVinent.toString().slice(-2)

  return (
    <div className="col col-12">

      <div className="row">
        <div className="t-Form-labelContainer">
          <label className="t-Form-label"> Quota de pagament del curs escolar {enguany}/{anyVinent}</label>
        </div>
      </div>

      <div className="row">
      <div className="col quota">


        <div className="quotaTitol">Curs: {props.curs.value} ({props.curs.id})</div>

        <div className="quotaDadesBancaries">

        { quota.total >0 && ( quota.dadesbancaries.IBAN && ( <>

          <div><span className="quotaNomCamp">Entitat bancària:</span> {quota.dadesbancaries.entitat}</div>

          <div> <span className="quotaNomCamp">nº IBAN:</span> {quota.dadesbancaries.IBAN}</div>

          </>) ) }

          <div className="quotaDadesPagamentCalendari"><span className="quotaNomCamp">Condicions:</span> {quota.dadesbancaries.condicions[props.curs.condicions]}</div>

        </div>

        <label> Conceptes </label>

        <table className="quota table">
          <tbody>

              { ( !props.curs.id.startsWith("ESO") && !props.curs.id.startsWith("BATX") ) &&
                (<>
                  <tr>
                    <th className="quotaNomCamp" colspan="2">Assegurança escolar obligatòria: </th>
                  </tr>

                  <tr>
                    {quota.menor28 && (<td className="col">Alumnat menor de 28 anys</td>)}
                    {!quota.menor28 && (<td className="col">Alumnat de 28 anys i major</td>)}
                    <td>{(quota.asseguranca).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td>
                  </tr>
                </>) }

          {/*props.nouAlumne !== true && (
          <>
          <tr>
            <td colspan="2"><input type="radio" id="repetidor0" name="repetidor" value="0" defaultChecked={ props.repetidor === 0 } onChange={(e) => handleChangeRepetidor(0)} ></input>
             <label for="repetidor0">Promociona a 2n</label></td>
          </tr>
          <tr>
            <td colspan="2"><input type="radio" id="repetidor1" name="repetidor" value="1" defaultChecked={ props.repetidor === 1 } onChange={(e) => handleChangeRepetidor(1)} ></input>
             <label for="repetidor1">Repetidor 1r</label></td>
          </tr>
          <tr>
            <td colspan="2"><input type="radio" id="repetidor2" name="repetidor" value="2" defaultChecked={ props.repetidor === 2 } onChange={(e) => handleChangeRepetidor(2)} ></input>
             <label for="repetidor2">Repetidor 2n</label></td>
          </tr>
          </>
          )
        */}

          { quota.moduls > 0 && (
          <tr>
            <td>Material mòduls matriculats:</td>
            <td>{(quota.moduls).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
            </td>
          </tr>)
          }

          { quota.matricula > 0 && (
          <tr>
            <td>{ props.curs.id.startsWith("CFPM") ? "Material curs:" : "Taxes:" }</td>
            <td>{(quota.matricula).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
            </td>
          </tr>)
          }



          {props.curs?.bonificacions === true && (<tr><th colspan="2" className="quotaNomCamp">Bonificacions / Exempcions:</th></tr>)}


          {props.curs?.bonificacions === true && (
                <tr>
                  <td>
                    <select id="bonificacions" name="bonificacions" defaultValue={bonificacio} onChange={(e) => handleChangeBonificacions(e.target.value)}>
                      {llista_bonificacions.map((m, k) =>
                        <option key={k} id={m.id} name={m.id} value={m.id}>{m.valor} ({m.descompte})</option>
                      )}
                    </select>
                  </td>
                  <td>
                    {quota.bonificacio >0 && "-"+(quota.bonificacio).toLocaleString(undefined, { 'maximumFractionDigits': 2 })+ "€" }
                  </td>
                </tr>
          )}

          { quota.formacio > 0 && (<tr><td className="quotaNomCamp">Despeses de formació:</td><td>{(quota.formacio).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td></tr>)}

          { /*quota.material > 0 && (<tr><td className="quotaNomCamp">Material del cicle:</td><td>{(quota.material).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td></tr>) */}


          { ( props.curs.id.startsWith("ESO") || props.curs.id.startsWith("BATX") )  ?

            (
              <>
              <tr><th colspan="2" className="quotaNomCamp">Preu curs escolar:</th></tr>
              </>

            ) 
            : 
            (
              <>
              {/* Si l'import del material és 0 no cal mostrar */}
              {!props.curs.material && (<tr><td className="quotaNomCamp"></td> </tr>)}
              {props.curs.material && (<tr><th colspan="2" className="quotaNomCamp">Material específic del cicle:</th></tr>)}
              </>
            )}

          {props.curs.material && props.curs.material.map((m, p) => {

            let disabled = false

            if ( props.assignatures?.length === 1 && /^FCT/.test(props.assignatures[0]) ) { // si només fan FCT no hi ha mai material
                return false
            } 
            else
            {

              let preu_material = 0;

              console.log("MATERIALS",props.curs.id);

              // MATERIALS
              switch (props.curs.id) 
              {
                // ESO 1, 2, 3 i 4
                case "ESOLOE01": 
                case "ESOLOE02": 
                case "ESOLOE03": 
                case "ESOLOE04": 
                // BATX 1 i 2
                case "BATXLOE2000": 
                case "BATXLOE3000": 
                case "BATXLOE20001": 
                case "BATXLOE20002": 
                case "BATXLOE30001": 
                case "BATXLOE30002": 
                  if( m.id === "material")
                  {
                      //NESE B
                      if(exemptPagament(props.iddocumen,props.becat) || exemptPagamentAmbLlibres(props.iddocument,props.becat))
                      { 
                        if(m.preu != 0)
                        {
                          m.preu = 0
                          canviaPreuMaterial(m.id, 0)
                        }
                      }


                        disabled = true; 
                        break; 
                  }

                  if( m.id === "ampa")
                  {
                        disabled = false; 
                        break; 
                  }

              break;

              /* CICLES MITJOS */

              //CFGM Forneria, pastisseria i confiteria 
                case "CFPMIA60":

                  // Estris de primer curs IA60
                  if (m.id === "estris1") {

                    if (props.nivellMax === 2) {

                      // si estem a 2n no mostrem el de primer
                      if (m.checked) {
                        m.checked = false
                        let mat = [...props.materials]
                        mat.filter(item => item.id === m.id).map((i) => i.checked = false)
                        props.setMaterials(mat)
                      }
                      return

                    } else {

                      if (!m.checked) {
                        m.checked = true
                        let mat = [...props.materials]
                        mat.filter(item => item.id === m.id).map((i) => i.checked = true)
                        props.setMaterials(mat)
                      }
                        disabled = true; 
                        break

                    }
                  }

                  // Estris de segon curs IA60
                  if (m.id === "estris2") {

                    if (props.nivellMax === 2) {

                      if (!m.checked) {
                        m.checked = true
                        let mat = [...props.materials]
                        mat.filter(item => item.id === m.id).map((i) => i.checked = true)
                        props.setMaterials(mat)
                      }
                        disabled = true; 
                        break

                    } else {

                      // si no estem a 2n no mostrem el de primer
                      if (m.checked) {
                        m.checked = false
                        let mat = [...props.materials]
                        mat.filter(item => item.id === m.id).map((i) => i.checked = false)
                        props.setMaterials(mat)
                      }
                      return

                    }
                  }

              //CFGM Comercialització de productes alimentaris 
                case "CFPMCM20N":
                case "CFPMCM20":

              //CFGM Cuina i gastronomia 
                case "CFPMHT10N":
                case "CFPMHT10":

              //CFGM Serveis en restauració 
                case "CFPMHT30N":
                case "CFPMHT30":

                  disabled = true;
                  break;

              //CFGS Agències de viatges i gestió d'esdeveniments 
                case "CFPSHTA0":

              //CFGS Guia, informació i assistència turístiques/Agències de viatges i gestió d'esdeveniments 
                case "CFPSHTF0N":
                case "CFPSHTF0":

              //CFGS Direcció de serveis en restauració 
                case "CFPSHTE0N":
                case "CFPSHTE0":

              //CFGS Direcció de cuina 
                case "CFPSHTD0N":
                case "CFPSHTD0":

                //CFGM Gestió Administrativa
                case "CFPMAG10N": 
                case "CFPMAG10": 

                // CFGM de Cures auxiliars d’infermeria
                case "CFPM1601N": 
                case "CFPM1601": 

                  const llistaAssignaturesPrimer = []
                  const llistaAssignaturesSegon = []

                  assignatures[props.curs.id].map((bloc) => {
                    bloc.subjects.map(
                      (assignatura) => {
                        if (assignatura.level === 1) { llistaAssignaturesPrimer.push(assignatura.id) }
                        if (assignatura.level === 2) { llistaAssignaturesSegon.push(assignatura.id) }
                      }
                    )
                  }
                  )

                  let matricula_parcial_1r = true;
                  if (llistaAssignaturesPrimer.length > 0) {
                    let copiaAssignatures1r = [...llistaAssignaturesPrimer]

                    matricula_parcial_1r = copiaAssignatures1r.filter((assignatura) => !props.assignatures.includes(assignatura)).length === 0 ? false : true

                  }

                  let matricula_parcial_2n = true;
                  if (llistaAssignaturesSegon.length > 0) {
                    let copiaAssignatures2n = [...llistaAssignaturesSegon]

                    matricula_parcial_2n = copiaAssignatures2n.filter((assignatura) => !props.assignatures.includes(assignatura)).length === 0 ? false : true

                  }

            // Material curs
            if(m.id=== "material" || m.id=== "menus")
            {
                  /*

              // per mantenir el preu original en el cas que s'hagi de canviar a 0 i posteriorment restaurar
              m.preuOrig = m.preuOrig ?? m.preu
              m.txtOrig = m.txtOrig ?? m.txt

              if (!matricula_parcial_1r || !matricula_parcial_2n) 
              {
                if (m.preu !== m.preuOrig) {
                  m.preu = m.preuOrig
                  m.txt = m.txtOrig
                  canviaPreuMaterial(m.id, m.preu)
                  canviaTxtMaterial(m.id, m.txt)
                }
              }
              else 
              {
                if (m.preu !== 0) {
                  m.preuOrig = m.preu
                  m.txtOrig= m.txt
                  m.preu = 0
                  m.txt = "Material curs"
                  canviaPreuMaterial(m.id, m.preu)
                  canviaTxtMaterial(m.id, m.txt)
                }
              }

              */

              // sempre obligatori - només canvia preu si no és necessari pel curs
              disabled = true;
              break;
            }

            if(m.id=== "mocadorcorbata")
            {
                //console.log("MOCADOR_CORBATA",m.checked,props.materials);

              // per mantenir el preu original en el cas que s'hagi de canviar a 0 i posteriorment restaurar
              m.preuMocadorCorbataOrig = m.preuMocadorCorbataOrig ?? m.preu
              m.txtMocadorCorbataOrig = m.txtMocadorCorbataOrig ?? m.txt

              //if (!matricula_parcial_1r || !matricula_parcial_2n) 
              if(props.nivellMax === 1)
              {
                  //  si fan 1r l'han de demanar 
                  if(!m.checked)
                    {
                    m.checked = true
                    let mat = [...props.materials]
                    mat.filter(item => item.id === m.id).map((i) => i.checked = true)
                    props.setMaterials(mat)
                  }

                   m.txt = m.txtMocadorCorbataOrig

                    disabled = true
                    break;
              }
              else
              {

                // si fan matricula parcial, pels nous és obligatori , els altres poden triar

                if(props.nouAlumne) 
                { 

                  if (!m.checked) {
                    m.checked = true
                    let mat = [...props.materials]
                    mat.filter(item => item.id === m.id).map((i) => i.checked = true)
                    props.setMaterials(mat)
                  }

                   m.txt = m.txtMocadorCorbataOrig

                    disabled = true 
                    break;
                }
                else
                {
                  if (m.checked) {
                    m.checked = false
                    let mat = [...props.materials]
                    mat.filter(item => item.id === m.id).map((i) => i.checked = false)
                    props.setMaterials(mat)
                  }

                }

                
              }

                  m.txt = m.txtMocadorCorbataOrig + " (opcional per repetidors)"
              
              disabled = false
              break; 
            }


            break;


            default:
                    disabled = true; 
                    break; 

              }
            }




            /* si els dos terminis no estan activats mostrem altres condicions de pagament */
          if(!config.dosTerminisEnabled) { props.curs.condicions = config.dosTerminisEnabledFalseCondicio } 

          return (
            <>
          <tr>
            <td>
            <input type="checkbox" id={m.id} name={m.id} value={m.preu} checked={isMaterialChecked(m.id)} disabled={disabled} onChange={(e) => handleChangeMaterial(e.target.id)} />
            <label for={m.id}>{m.txt}</label>
            </td>
            <td>
            {m.preu}&euro;
          </td></tr>

              {/* isMaterialChecked(m.id) && m.info !== undefined ?*/}
              { m.info !== undefined ?
                    (<tr>
                      <td>
                        <div style={{ maxWidth: '75%', border: '2px solid #c00000', margin: '5px 0px', padding: 5 }} dangerouslySetInnerHTML={{ __html: m.info }} />
                      </td>
                      <td>
                      </td>
                    </tr>)
                    : null
                }

          </>
          )}
          )}

          <tr><th className="quotaNomCamp" colSpan="2"> Total a abonar a compte:</th></tr>

          <tr>

          { ( props.curs.id.startsWith("ESO") || props.curs.id.startsWith("BATX") )  ?
          (<>
           <td>Total: 
           { 
           exemptPagament(props.iddocument,props.becat) ? 
           ( <small>&nbsp;<i>Exempt de pagament de matrícula i llibres</i></small>)
           : exemptPagamentAmbLlibres(props.iddocument,props.becat) && ( <small>&nbsp;<i>Exempt del pagament de la matrícula (material i sortides) si que s'hauran de comprar els llibres.</i></small>)}
           
            </td>
          </>)
          :
          (<>
          { quota.menor28 ? (<td>Alumnat menor de 28 anys</td>) : (<td>Alumnat de 28 anys i major: </td>)}
          </>) 
          }

           <td className="quotaTotal"> {(quota.total).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;</td>

          </tr>



          <tr>
          { !quota.menor28 && (<td colSpan={2}>

            <small><i>(Els alumnes més grans de 28 anys que disposin de la targeta sanitària del Servei Català de la Salut tenen coberta l'assistència sanitària, però no tenen dret a les prestacions que dona l'assegurança escolar obligatòria.)</i></small>

          </td>)}

          </tr>

          { ( props.curs.condicions === 1 && config.dosTerminisEnabled ) && 

          quota.bonificacio < quota.matricula ? 

          (<tr>
            <td colspan="2">
            <input type="checkbox" id="dosTerminis" name="dosTerminis" value={true} checked={props.dosTerminis} onChange={(e) => props.setDosTerminis(e.target.checked)} />
            <label for="dosTerminis">Vull fer el pagament en 2 terminis</label>
            </td>
            <td>
          </td></tr>)
          : (<>{props.setDosTerminis(false)}</>)

          }


           { props.dosTerminis && (
              <React.Fragment>
              <tr>
                <td>
                  Pagament 1r termini:
                </td>
                <td>
                  { quota.termini1 && (quota.termini1).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
                </td>
              </tr>
              <tr>
                <td>
                  Pagament 2n termini:
                </td>
                <td>
                  { quota.termini2 && (quota.termini2).toLocaleString(undefined, { 'maximumFractionDigits': 2 })}&euro;
                </td>
              </tr>
              </React.Fragment>
            )}

          </tbody>
        </table>

      </div>
      </div>

    </div >
  )
  
 }
