import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'react-slidedown/lib/slidedown.css'
import Dropdown from '../../dropdown'
import { FormSelectYesNo } from '../formelements'
import config from '../../../config'

export function EnrolmentFormAuthorizations(props) {

  const [errors, setErrors] = useState({});

  const handleErrors = (nom, error) => {
    //console.log('error', nom, error)
    setErrors(error)
  }

  /* AUTORITZACIONS */
  const [authorizationsOpen, setAuthorizationsOpen] = useState(true);

  const toggleAuthorizationsOpen = () => {
    setAuthorizationsOpen(!authorizationsOpen);
  }

  const [authIntranet, setAuthIntranet ] = useState(false);
  const [authPublicacions, setAuthPublicacions ] = useState(false);
  const [authMaterial, setAuthMaterial ] = useState(false);
  const [authMaterialInicials, setAuthMaterialInicials ] = useState(false);
  const [authInternet, setAuthInternet ] = useState(false);

  const [authDretsImatge , setAuthDretsImatge ] = useState("0");

  const [authCompromis, setAuthCompromis ] = useState("0");
  const [authGDPR, setAuthGDPR ] = useState("0");
  const [authSortides, setAuthSortides ] = useState("0");
  const [authAMPA, setAuthAMPA ] = useState("0");
  const [authMedica, setAuthMedica ] = useState("0");
  const [authAlcohol, setAuthAlcohol ] = useState("0");
  const [authParacetamol, setAuthParacetamol ] = useState("0");
  const [authResponsable, setAuthResponsable ] = useState("0");
  const [authMobils, setAuthMobils] = useState("0");
  const [authPortatils, setAuthPortatils ] = useState("0");

  const [dadesAutoritzacions, setDadesAutoritzacions] = useState({validat: false});

  const handleDadesAutoritzacions = (noError) => {
    let d = {
     "validat": noError,
  
    /* DADES AUTORITZACIONS */
    "authIntranet": authIntranet,
    "authPublicacions": authPublicacions,
    "authMaterial": authMaterial,
    "authMaterialInicials": authMaterialInicials,
    "authInternet": authInternet,
    "authCompromis": authCompromis,
    "authGDPR": authGDPR,
    "authDretsImatge": authDretsImatge,
    "authSortides": authSortides,
    "authAMPA": authAMPA,
    "authAlcohol": authAlcohol,
    "authMedica": authMedica,
    "authParacetamol": authParacetamol,
    "authResponsable": authResponsable,
    "authMobils": authMobils,
    "authPortatils": authPortatils,
    }
  
      console.log("DADES_AUTORITZACIONS",d)
      setDadesAutoritzacions(d)
      props.handleDadesAutoritzacions(d)
    }

  const validaAuthIntranet = () => {
    return authIntranet ? null : "Heu d'indicar una resposta"
  }

  const validaAuthPublicacions= () => {
    return authPublicacions ? null : "Heu d'indicar una resposta"
  }

  const validaAuthMaterial= () => {
    return authMaterial ? null : "Heu d'indicar una resposta"
  }

  const validaAuthMaterialInicials = () => {
    return authMaterialInicials ? null : "Heu d'indicar una resposta"
  }

  const validaAuthDretsImatge = () => {
    //només obligatori pels menors de 14
    //if(props.dadesPersonals.edat >= 14 ) return null
    return authDretsImatge ? null : "Heu d'indicar una resposta"
  }

  const validaAuthInternet= () => {
    return authInternet ? null : "Heu d'indicar una resposta"
  }

  const validaAuthSortides = () => {
    return authSortides === "1" ? null : "Aquest camp és obligatori"
  }

  const validaAuthCompromis = () => {
    return authCompromis === "1" ? null : "Aquest camp és obligatori"
  }

  const validaAuthMobils = () => {
    return authMobils === "1" ? null : "Aquest camp és obligatori"
  }

  const validaAuthPortatils = () => {
    return authPortatils === "1" ? null : "Aquest camp és obligatori"
  }

  const validaAuthGDPR= () => {
    return authGDPR === "1" ? null : "Aquest camp és obligatori"
  }

  const validaAuthResponsable= () => {
    return authResponsable === "1" ? null : "Aquest camp és obligatori"
  }

 const handleAuthDretsImatge = (t) => {

      setAuthDretsImatge( t === "0" ? "1" : "0") 

      handleDadesAutoritzacions(false)
 }


 const handleChangeCompromis = (t) => {

      setAuthCompromis(t === "0" ? "1" : "0") 

      handleDadesAutoritzacions(false)
 }

 const handleChangeGDPR = (t) => {

      setAuthGDPR(t === "0" ? "1" : "0") 

      //if(t === "1") handleDadesAutoritzacions(false)
      handleDadesAutoritzacions(false)
 }

 const handleChangeResponsable = (t) => {

      setAuthResponsable(t === "0" ? "1" : "0") 

      //if(t === "1") handleDadesAutoritzacions(false)
      handleDadesAutoritzacions(false)
 }

 const handleChangeMobils = (t) => {

      setAuthMobils(t === "0" ? "1" : "0") 

      handleDadesAutoritzacions(false)
 }

 const handleChangePortatils = (t) => {

      setAuthPortatils(t === "0" ? "1" : "0") 

      handleDadesAutoritzacions(false)
 }

 const handleChangeSortides = (t) => {

      setAuthSortides(t === "0" ? "1" : "0") 

      handleDadesAutoritzacions(false)
 }

 const handleChangeAMPA= (t) => {

      setAuthAMPA(t === "0" ? "1" : "0") 

      handleDadesAutoritzacions(false)
 }

 const handleChangeAlcohol = (t) => {

      setAuthAlcohol(t === "0" ? "1" : "0") 

      handleDadesAutoritzacions(false)
 }

 const handleChangeMedica= (t) => {

      setAuthMedica(t === "0" ? "1" : "0") 

      handleDadesAutoritzacions(false)
 }

 const handleChangeParacetamol = (t) => {

      setAuthParacetamol(t === "0" ? "1" : "0") 

      handleDadesAutoritzacions(false)
 }






/* VALIDA AUTORITZACIONS */
const validaAutoritzacions = (e) => {
  if(e) e.preventDefault();

  let error = {}

  /* 
  error.authIntranet = validaAuthIntranet();
  handleErrors('authIntranet',error)
  
  error.authPublicacions = validaAuthPublicacions();
  handleErrors('authPublicacions',error)
  
  error.authMaterial = validaAuthMaterial();
  handleErrors('authMaterial',error)
  
  error.authMaterialInicials = validaAuthMaterialInicials();
  handleErrors('authMaterialInicials',error)
  
  error.authInternet = validaAuthInternet();
  handleErrors('authInternet',error)
  */

  error.authCompromis = validaAuthCompromis();
  handleErrors('authCompromis',error)

  error.authMobils = validaAuthMobils();
  handleErrors('authMobils',error)

  if(props.dadesEstudis.curs.id.startsWith("ESOLOE") || props.dadesEstudis.curs.id.startsWith("BATXLOE"))
  {
    error.authPortatils = validaAuthPortatils();
    handleErrors('authPortatils',error)
  }

  error.authGDPR = validaAuthGDPR();
  handleErrors('authGDPR',error)

  error.authResponsable = validaAuthResponsable();
  handleErrors('authResponsable',error)

  error.authDretsImatge = validaAuthDretsImatge();
  handleErrors('authDretsImatge',error)


  if( props.dadesPersonals.edat >= 18 ) {

    error.authSortides = validaAuthSortides();
    handleErrors('authSortides',error)

  }


  setErrors(error)

  let noError = true;
  //DEVELOP 
  if(!config.develop) // bypass errors if develop == true
  {
    Object.keys(error).map( (k) => { if(error[k] !== null) noError = false } )
  }

  //console.log('ERROR',error);

  handleDadesAutoritzacions(noError)

  if(noError)
  {
     setAuthorizationsOpen(false);
  }

  return noError;
}

  return (
    <div className="container authorizations">
      <div className="row">
        <div className="col col-12 ">
          <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
            <div className="t-Region-header">
              <div className="t-Region-headerItems  t-Region-headerItems--controls">
                <button
                  className="t-Button t-Button--icon t-Button--hideShow"
                  type="button"
                  onClick={toggleAuthorizationsOpen}
                >
                  {authorizationsOpen && (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      fontSize="18"
                      className="t-Icon"
                    />
                  )}
                  {!authorizationsOpen && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      fontSize="18"
                      className="t-Icon"
                    />
                  )}
                </button>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--title">
                <h2 className="t-Region-title a-Collapsible-heading">
                  Protecció de dades
                </h2>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
            </div>
            <div className="t-Region-bodyWrap">
              <Dropdown open={authorizationsOpen}>
                <div className="t-Region-body a-Collapsible-content">
                  <div className="container">
                    <div className="row">
                      <div className="col col-12 ">
                        <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                          <div className="t-Region-bodyWrap">
                            <div className="t-Region-body">
                              <div className="container">

                                   { props.dadesPersonals.edat >= 16 ? (
                                     <>
                                <div className="row">
                                  <div className="col col-12 ">

                                    <h4>
{/*                                    Carta de compromís educatiu: Alumnat menors 16 anys complerts. Curs {props.enguany}-{props.anyVinent} */}
                                    Carta de compromís educatiu. Curs {props.enguany}-{props.anyVinent} 
                                    </h4>

                                          <p>La carta de compromís educatiu és un document que té com a finalitat potenciar la comunicació, la participació, la implicació, el compromís i la 
                                            coresponsabilitat entre els centres i les famílies en l'educació d'infants i joves.  Aquesta carta té per objectius principals, entre d'altres:</p>

                                          <ol>
                                            <li>Compartir amb les famílies els principis i els continguts del projecte educatiu del centre i, si escau, els projectes educatius de l'entorn.</li>
                                            <li>Millorar la informació i la comunicació entre el centre i les famílies.</li>
                                            <li>Facilitar a les famílies l'exercici dels seus drets i el compliment dels deures.</li>
                                            <li>Fomentar la convivència i el bon clima escolar.</li>
                                            <li>Coresponsabilitzar el centre i la família en l'èxit acadèmic, personal i social dels alumnes i també en el compromís cívic dels infants i joves.</li>
                                          </ol>

                                          <p>El centre, les famílies i l'alumnat signem aquest document i ens comprometem amb les mesures i recomanacions aquí subscrites per millorar 
                                            així el clima escolar, la comunicació i els resultats acadèmics de l'alumnat.</p>

                                    <h5>
                                    Per part del centre:
                                    </h5>

                                      <ol>
                                        <li>Facilitar una bona relació amb l'alumnat establint canals de comunicació per facilitar el seguiment de la progressió acadèmica, professional i personal de l'alumnat. S'informa tres cops al curs de l'avaluació i evolució de l'alumnat.</li>
                                        <li>Instar a l'alumnat a respectar les normes de funcionament de l'escola, en particular, les que afecten la convivència escolar, el desenvolupament normal de les classes i les activitats complementàries.</li>
                                        <li>Informar la família dels resultats de les avaluacions i de l'evolució acadèmica i personal de l'alumnat.</li>
                                        <li>Oferir uns continguts i una metodologia d'ensenyament que procuri estimular el progrés de l'alumne/a vers l'obtenció dels millors resultats acadèmics i l'adquisició de destreses i habilitats per aconseguir l'excel·lència en el nostre ofici, d'acord amb les seves capacitats, mitjançant el treball personalitzat i l'avaluació continuada.</li>
                                        <li>Facilitar unes pràctiques de qualitat a l'alumne/a per tal d'aconseguir completar de manera correcta la seva formació en l'ofici escollit, arribar a ser un professional d'èxit i aportar valor afegit al sector.</li>
                                      </ol>


                                    <h5>
                                    Per part de la família:
                                    </h5>

                                          <ol>
                                            <li>Contribuir a crear un vincle de confiança mútua amb els professionals de l'escola per a treballar conjuntament en la construcció del futur del nostre fill o filla.</li>
                                            <li>Instar el fill o la filla a respectar les normes de funcionament de l'escola, en particular, les que afecten la normativa del cicle formatiu, uniformitat, sanitat,...</li>
                                            <li>Atendre les peticions d'entrevistes i/o de comunicacions que formuli l'escola.</li>
                                            <li>Compartir amb l'escola tota la informació que sigui rellevant pel procés d'aprenentatge i progrés personal del nostre fill o filla.</li>
                                            <li>Col·laborar amb l'escola en el procés de millora contínua, amb suggeriments i reflexions de tot allò que pugui ser susceptible de ser millorat, a través dels canals establerts (entrevistes, web, correu electrònic...)</li>
                                          </ol>

                                    <h5>
                                    Per part de l'alumne:
                                    </h5>

                                          <ol>
                                            <li>Assistir a classe, participar en les activitats formatives previstes a la programació general del centre i respectar els horaris establerts.</li>
                                            <li>Respectar les Normes bàsiques de convivència, en particular aquelles que afecten el desenvolupament normal de les classes i activitats professionalitzadores prestant especial esment a: atenció, esforç, col·laboració, educació, higiene personal, uniformitat, respecte a les persones i als mitjans disponibles, limitació de l'ús de telèfons mòbils i xarxes socials durant la jornada escolar.</li>
                                            <li>Quan l'alumnat desenvolupi el rol de client, s'ha de comportar amb respecte i educació amb els companys que fan les pràctiques, respectant horaris i temps del servei. També ha de mostrar una bona predisposició a tastar i menjar nous plats i productes que no li són afins, per tal de completar la seva formació.</li>
                                            <li>Realitzar les tasques encomanades pel professorat en l'exercici de les seves funcions docents.</li>
                                          </ol>

                                    <p>
                                      <input type="checkbox" id="authCompromis" name="authCompromis" value={authCompromis} checked={authCompromis === "1" ? true : false } onChange={ (t) => { handleChangeCompromis(t.target.value) } } required/>
                                        <label htmlFor="authCompromis">Perquè així consti, signem aquesta carta de compromís educatiu.</label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authCompromis}</span>
                                    </p>


                                  </div>
                                </div>

                                        </>
                                   ) : (
                                     <>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    {/* Carta de compromís educatiu: Alumnat menors 16 anys complerts. Curs {props.enguany}-{props.anyVinent} */}
                                    Carta de compromís educatiu. Curs {props.enguany}-{props.anyVinent} 
                                    </h4>

                                          <p>La carta de compromís educatiu és un document que té com a finalitat potenciar la comunicació, la participació, la implicació, el compromís i la 
                                            coresponsabilitat entre els centres i les famílies en l'educació d'infants i joves.  Aquesta carta té per objectius principals, entre d'altres:</p>

                                          <ol>
                                            <li>Compartir amb les famílies els principis i els continguts del projecte educatiu del centre i, si escau, els projectes educatius de l'entorn.</li>
                                            <li>Millorar la informació i la comunicació entre el centre i les famílies.</li>
                                            <li>Facilitar a les famílies l'exercici dels seus drets i el compliment dels deures.</li>
                                            <li>Fomentar la convivència i el bon clima escolar.</li>
                                            <li>Coresponsabilitzar el centre i la família en l'èxit acadèmic, personal i social dels alumnes i també en el compromís cívic dels infants i joves.</li>
                                          </ol>

                                          <p>El centre, les famílies i l'alumnat signem aquest document i ens comprometem amb les mesures i recomanacions aquí subscrites per millorar 
                                            així el clima escolar, la comunicació i els resultats acadèmics de l'alumnat.</p>

                                    <h5>
                                    Per part del centre:
                                    </h5>

                                      <ol>
                                        <li>Facilitar una bona relació amb l'alumnat establint canals de comunicació per facilitar el seguiment de la progressió acadèmica, professional i personal de l'alumnat. S'informa tres cops al curs de l'avaluació i evolució de l'alumnat.</li>
                                        <li>Instar a l'alumnat a respectar les normes de funcionament de l'escola, en particular, les que afecten la convivència escolar, el desenvolupament normal de les classes i les activitats complementàries.</li>
                                        <li>Informar la família dels resultats de les avaluacions i de l'evolució acadèmica i personal de l'alumnat.</li>
                                        <li>Oferir uns continguts i una metodologia d'ensenyament que procuri estimular el progrés de l'alumne/a vers l'obtenció dels millors resultats acadèmics i l'adquisició de destreses i habilitats per aconseguir l'excel·lència en el nostre ofici, d'acord amb les seves capacitats, mitjançant el treball personalitzat i l'avaluació continuada.</li>
                                        <li>Facilitar unes pràctiques de qualitat a l'alumne/a per tal d'aconseguir completar de manera correcta la seva formació en l'ofici escollit, arribar a ser un professional d'èxit i aportar valor afegit al sector.</li>
                                        <li>Informar a la família del projecte educatiu de centre (PEC) i de les normés d'organtizació i funcionament del centre (NOFC) incloent-hi els acords de centre sobre l'ús dels telèfons mòbils.</li>
                                      </ol>


                                    <h5>
                                    Per part de la família:
                                    </h5>

                                          <ol>
                                            <li>Contribuir a crear un vincle de confiança mútua amb els professionals de l'escola per a treballar conjuntament en la construcció del futur del nostre fill o filla.</li>
                                            <li>Instar el fill o la filla a respectar les normes de funcionament de l'escola, en particular, les que afecten la normativa del cicle formatiu, uniformitat, sanitat,...</li>
                                            <li>Atendre les peticions d'entrevistes i/o de comunicacions que formuli l'escola.</li>
                                            <li>Compartir amb l'escola tota la informació que sigui rellevant pel procés d'aprenentatge i progrés personal del nostre fill o filla.</li>
                                            <li>Col·laborar amb l'escola en el procés de millora contínua, amb suggeriments i reflexions de tot allò que pugui ser susceptible de ser millorat, a través dels canals establerts (entrevistes, web, correu electrònic...)</li>
                                            <li>Respectar les normes d'organització i funcionament del centre (NOFC) i la regulació dels telèfons mòbils que s'hi específica.</li>
                                          </ol>

                                    <h5>
                                    Per part de l'alumne:
                                    </h5>

                                          <ol>
                                            <li>Assistir a classe, participar en les activitats formatives previstes a la programació general del centre i respectar els horaris establerts.</li>
                                            <li>Respectar les Normes bàsiques de convivència, en particular aquelles que afecten el desenvolupament normal de les classes i activitats professionalitzadores prestant especial esment a: atenció, esforç, col·laboració, educació, higiene personal, uniformitat, respecte a les persones i als mitjans disponibles, limitació de l'ús de telèfons mòbils i xarxes socials durant la jornada escolar.</li>
                                            <li>Realitzar les tasques encomanades pel professorat en l'exercici de les seves funcions docents.</li>
                                          </ol>

                                    <p>
                                      <input type="checkbox" id="authCompromis" name="authCompromis" value={authCompromis} checked={authCompromis === "1" ? true : false } onChange={ (t) => { handleChangeCompromis(t.target.value) } } required/>
                                        <label htmlFor="authCompromis">Perquè així consti, signem aquesta carta de compromís educatiu.</label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authCompromis}</span>
                                    </p>

                                  </div>
                                </div>

                                        </>
                                    )
                                  }

<br/>

                                {/* AUTORITZACIONS ESPECIFIQUES MOBIL ESO */}
                                { ( props.dadesEstudis.curs.id.startsWith("ESOLOE") ) ? (<>
                              <div className="row">
                              <div className="col col-12 ">
                                <h4>
                                  Ús del mòbil en el centre educatiu
                                </h4>
                                <p>

                                  Des de fa molts anys el nostre centre és un centre on no es poden utilitzar els
                                  dispositius mòbils. Tot i així, tenim petits problemes de disciplina ja que hi ha alumnat
                                  que l’utilitza en intercanvis de classes o en altres moment. Aquest fet pot comportar la
                                  vulneració del dret d’imatge i intimitat d’altres alumnes, si es fan fotos, vídeos...<br/>

                                  <br/>

                                  Per tant, després de diferents reflexions al voltant d’aquests aspectes i altres, l’Equip
                                  Directiu amb el recolzament de l’inspector i l’aprovació del Consell Escolar ha decidit
                                  millorar el protocol d’ús dels mòbils pel curs 24/25 amb els següents punts:<br/>


                                  <ol>
                                        <li>A primera hora del matí, el professor/a que estigui a cada grup ha de recollir els mòbils apagats de l’alumnat i els ha de ficar en la caixa especial per guardar-los.</li>
                                        <li>El professor/a que recull els mòbils anota l’alumnat que deixa el seu mòbil.</li>
                                        <li>La caixa on es depositen els mòbils estarà en un espai tancat en clau.</li>
                                        <li>Si es veu algun mòbil en el centre, encara que l’alumne o alumna no estigui fent ús, es confiscarà durant tot el dia i la família haurà de venir a per ell.</li>
                                        <li>Qualsevol membre de la comunitat educativa pot requisar el mòbil i portar-lo a consergeria perquè la família el vingui a buscar al final de la jornada.</li>
                                        <li>Si l’alumnat necessita fer una trucada o la família necessita contactar amb els seus fills/es està a la seva disposició el telèfon del centre (937851143)</li>
                                        <li>El centre no es responsabilitza dels mòbils. Tot mòbil que entra al centre estarà exempt de qualsevol responsabilitat (trencament, ratllades, pèrdues, robatoris... )</li>

                                  </ol>


                                  <input type="checkbox" id="authMobils" name="authMobils" value={authMobils} checked={authMobils === "1" ? true : false } onChange={ (t) => { handleChangeMobils(t.target.value) } } required/>
                                  <label htmlFor="authMobils"> He llegit i accepto el protocol</label>
                                  <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authMobils}</span>
                                </p>


                              </div>
                            </div>

                                  </>
                                ) : ( 
                                  <>
                              <div className="row">
                              <div className="col col-12 ">
                                <h4>
                                  Ús del mòbil en el centre educatiu
                                </h4>
                                <p>
                                  D'acord amb el Pla de Convivència del Centre us volem informar sobre la
                                  NORMATIVA QUE REGULA L'ÚS DE TELÈFONS MÒBILS I APARELLS
                                  ELECTRÒNICS A L'INSTITUT. (basada en la Llei 12/2009, del 10 de juliol, d'Educació)  <br/>

                                  <ol>

                                  <li>L'ús del mòbil i aparells de reproducció de música està prohibit en horari de classe, dins l'aula teòrica i pràctica. 
                                    L'alumnat de PFI-PTT no els podrà utilitzar tampoc en tot el centre. Per tal de respectar i garantir el dret d'imatge està totalment prohibit fer fotos i/o vídeos dins
                                     el recinte escolar. Aquests aparells, només es podran utilitzar amb usos pedagògics quan el professor/a ho indiqui.  </li>

                                  <li>A l'aula, el telèfon mòbil haurà d'estar en silenci i guardat.</li>
                                  <li>Està totalment prohibit carregar els mòbils al centre.</li>
                                  <li>Si un/a professor/a detecta un alumne/a fent un ús incorrecte del mòbil durant la classe, pot demanar a l'alumne/a que apagui el dispositiu i el deixi a sobre de la taula del professor fins al final de la classe.</li>
                                  <li>En el cas que un/a alumne/a reincideixi es considerarà com una falta lleu i s'aplicarà el Reglament de Règim Intern del centre. 
                                    L'alumnat de PTT-PFI que usi el mòbil en hores de classe de manera reincident, un cop ha estat ja avisat marxarà cap a casa amb un comunicat d'incidència.</li>
                                  <li>EL CENTRE NO ES FA RESPONSABLE del desperfecte, de la pèrdua o sostracció dels aparells que, sota la responsabilitat de cada alumne/a, es portin a l'institut.</li>
                                  </ol>


                                  <input type="checkbox" id="authMobils" name="authMobils" value={authMobils} checked={authMobils === "1" ? true : false } onChange={ (t) => { handleChangeMobils(t.target.value) } } required/>
                                  <label htmlFor="authMobils"> He llegit i accepto la normativa del centre que regula l'ús del mòbil i altres aparells electrònics</label>
                                  <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authMobils}</span>
                                </p>


                              </div>
                            </div>
                            </>)}

                                <br/>


                                {/* AUTORITZACIONS ESPECIFIQUES ESO i BATX */}
                                { ( props.dadesEstudis.curs.id.startsWith("ESOLOE") || props.dadesEstudis.curs.id.startsWith("BATXLOE")) && (<>
                              <div className="row">
                              <div className="col col-12 ">
                                <h4>
                                Condicions de recepció de l'ordinador portàtil
                                </h4>
                                <p>

                                En el marc del Pla d’educació digital de Catalunya, el Projecte d’acceleració de la
                                transformació digital desplega com una de les intervencions dotar d’equipaments
                                l’alumnat dels centres. L’institut Cavall Bernat lliurarà als alumnes d ́ESO i Batxillerat un
                                ordinador amb les següents característiques: <br/>
                                                                  <br/>

                                    <div className="quadrePortatilAutoritzacions">
                                        Lenovo model 300E, tàctils de 11,6”.
                                        Chromebook Segona Generació
                                    </div>


                                                                  <br/>

                                Des del centre es posa a la teva disposició aquest aparell perquè en facis el bon ús
                                necessari per al teu aprenentatge. És responsabilitat teva assegurar-te que es va
                                mantenir en bon estat, conscient que hauràs de tornar-la al final del curs o del teu
                                període d'escolarització a l'institut Cavall Bernat.
                                Recordeu, aquest ordinador no és de la vostra propietat, sinó que l'institut et deixa en
                                préstec.  <br/>


                                  Compromísos:<br/>

                                  <ol>
                                        <li>Portar l'ordinador al centre sempre, per poder treballar adequadament o per revisar-lo.</li> 
                                        <li> Portar l'ordinador carregat de casa. És necessari utilitzar el carregador corresponent a l'ordinador i no cap altre.</li> 
                                        <li> No marcar-lo, ratllar-lo o personalitzar-lo. Cal mantenir la identificació amb un etiquetatge discret que es pugui extreure quan es torni.</li> 
                                        <li> Tornar l'ordinador en perfecte estat al final de la teva escolarització al centre. En concret, heu d'incloure qualsevol accessori que s'hagi facilitat juntament amb l'aparell (adaptador, carregador, etc.). Cal que estigui net, lliure de pols, esquitxades, empremtes,etc.</li> 
                                        <li> La pèrdua de l'ordinador, el carregador o qualsevol trencament es considera una falta greu, amb la conseqüent sanció disciplinària i econòmica.</li> 
                                        <li> Si es detecta qualsevol mal ús, es retirarà l'ordinador. El seu ús és acadèmic, no pas per altres possibilitats fora de les indicacions específiques dels docents.</li> 
                                  </ol>

                                  <input type="checkbox" id="authPortatils" name="authPortatils" value={authPortatils} checked={authPortatils === "1" ? true : false } onChange={ (t) => { handleChangePortatils(t.target.value) } } required/>
                                  <label htmlFor="authPortatils"> He llegit i accepto la normativa</label>
                                  <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authPortatils}</span>
                                </p>


                              </div>
                            </div>

                                  <br/>
                                  </>
                                )}



                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                       Drets d'imatge
                                    </h4>
                                    <p>
                                      <input type="checkbox" 
                                      id="authDretsImatge" 
                                      name="authDretsImatge" 
                                      value={authDretsImatge} 
                                      checked={authDretsImatge === "1" ? true : false } 
                                      onChange={ (t) => { handleAuthDretsImatge(t.target.value) } 
                                      }/>

                                      <label htmlFor="authDretsImatge">
                                      AUTORITZO 
                                      { props.dadesPersonals.edat < 18 ? (<> que la imatge i/o veu de l'alumne/a matriculat/ada </> ) : (<> que la meva imatge i/o veu </>)}
                                      pugui aparèixer en fotografies i vídeos corresponents a 
                                      les activitats lectives, complementàries, extraescolars i de promoció i difusó organitzades per l'institut i publicades a pàgines WEB,
                                      xares socials, filmacions no comercials o revistes i publicacions d'àmbit educatiu.
                                          </label>
                                      <span id="auhtDretsImatge_ERROR" className="a-Form-error">{errors.authDretsImatge}</span>
                                      </p>
                                </div>
                               </div>

                                      <br/>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                      Informació bàsica sobre protecció de dades
                                    </h4>
                                    <p>
                                      <b>Responsable del tractament:</b> Direcció del centre educatiu.<br/>
                                      <b>Finalitat:</b> Autorització difusió d'imatge/veu i material elaborat als espais de comunicació i difusió del centre.<br/>
                                      <b>Legitimació:</b> Consentiment de l'interessat o de la persona que ostenta la tutoria legal en cas de menors d'edat.<br/>
                                      <b>Destinataris:</b> Les dades no es comunicaran a tercers, excepte en els casos previstos per llei, o si ho heu consentit
                                      prèviament.<br />
                                      <b>Drets:</b> Accedir a les dades, rectificar-les, suprimir-les, oposar-se al tractament i sol·licitar-ne la limitació.<br />
                                      <b>Informació addicional:</b> Podeu consultar la informació addicional i detallada sobre protecció de dades a la pàgina:
                                    </p>
                                      <ul>
                                        <li><a href="https://educacio.gencat.cat/ca/Detall/alumnes-centres-departament" target="_blank">https://educacio.gencat.cat/ca/Detall/alumnes-centres-departament</a> </li>
                                        <li><a href="https://educacio.gencat.cat/ca/Detall/personal-centres-departament" target="_blank">https://educacio.gencat.cat/ca/Detall/personal-centres-departament</a></li>
                                      </ul>
                                    <br/>



                                    <p>
                                      <input type="checkbox" id="authGDPR" name="authGDPR" value={authGDPR} checked={authGDPR === "1" ? true : false } onChange={ (t) => { handleChangeGDPR(t.target.value) } } required/>
                                      <label htmlFor="authGDPR">He llegit la informació bàsica sobre protecció de dades i autoritzo el tractament de les meves dades personals.</label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authGDPR}</span>
                                    </p>

                                  </div>
                                </div>

                                      <br/>

                                { props.dadesPersonals.edat < 18 ? (
                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització per a realitzar activitats fora del centre
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authSortides" name="authSortides" value={authSortides} checked={authSortides === "1" ? true : false } onChange={ (t) => { handleChangeSortides(t.target.value) } } required/>
                                      <label htmlFor="authSortides">
                                        AUTORITZO a l'alumne/a matriculat/ada a:
                                        <ul>
                                          <li>participar en totes les activitats i sortides formatives fora del centre.</li>
                                          <li>desplaçar-se/tornar pel seu compte fins a/des d'un punt de trobada preestablert en
                                          activitats o sortides fora del centre.</li>
                                          {/*<li>a sortir sol/a del recinte escolar (Institut, Torre Mossèn Homs o Escola Mpal. d'Art) un cop
                                          acabades les activitats lectives, a l'hora del descans o bé per motius 
                                          excepcionals com canvis d'horari, prohibició d'entrar a classe per arribada amb
                                          retard o no portar el material o uniforme adequats.</li> */}
                                        </ul>

                                          </label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authSortides}</span>
                                    </p>

                                  </div>
                                </div>) : (
                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Responsabilització sortides
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authSortides" name="authSortides" value={authSortides} checked={authSortides === "1" ? true : false } onChange={ (t) => { handleChangeSortides(t.target.value) } } required/>
                                      <label htmlFor="authSortides"> Em RESPONSABILITZO dels desplaçaments que faci pel meu compte cap/des de punts preestablerts en activitats i sortides del centre.  </label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authSortides}</span>
                                    </p>

                                  <br/>

                                  </div>
                                </div>)
                                }


                                { props.dadesPersonals.edat < 18 && ( props.dadesEstudis.curs.id.startsWith("CFPM") || props.dadesEstudis.curs.id.startsWith("CFPS") ) && (
                                  <>                                
                                  <br/>
                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització tast de begudes alchohòliques:
                                    </h4>
                                    <p>
                                    Dins dels estudis s'inclou el coneixement de les característiques del vi realitzant, a vegades, pràctiques de tast. El tast del vi implica tastar-lo en una
quantitat molt petita, solament per apreciar-ne les qualitats organolèptiques.
                                    </p>
                                    <br/>
                                    <p>
                                      <input type="checkbox" id="authAlcohol" name="authAlcohol" value={authAlcohol} checked={authAlcohol === "1" ? true : false } onChange={ (t) => { handleChangeAlcohol(t.target.value) } } required/>
                                      <label htmlFor="authAlcohol"> AUTORITZO a l'alumne/a matriculat/ada a fer el tast de begudes alcohòliques amb una finalitat didàctica</label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authAlcohol}</span>
                                    </p>

                                  </div>
                                </div>
                                </>
                                )}
                                

                                { props.dadesPersonals.edat < 18 && (
                                  <>
                                  <br/>
                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització mèdica
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authMedica" name="authMedica" value={authMedica} checked={authMedica === "1" ? true : false } onChange={ (t) => { handleChangeMedica(t.target.value) } } required/>
                                      <label htmlFor="authMedica"> AUTORITZO en cas que es produís una urgència mèdica imprevista greu i l'Institut l'hagués d'acompanyar a un centre mèdic, mentre no arriba la família, autoritzo el professor acompanyant a prendre totes les decisions oportunes (proves, anàlisis, transfusions ...). </label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authMedica}</span>
                                    </p>


                                  </div>
                                </div>


{/*
                                      <br/>

                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització per a l'administració de paracetamol
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authParacetamol" name="authParacetamol" value={authParacetamol} checked={authParacetamol === "1" ? true : false } onChange={ (t) => { handleChangeParacetamol(t.target.value) } } required/>
                                      <label htmlFor="authParacetamol"> AUTORITZO  El centre educatiu a administrar paracetamol al meu fill/a, en cas de presentar febre superior a 38℃ de temperatura corporal mentre s'espera l'arribada d'algun familiar que el pugui portar a casa. </label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authParacetamol}</span>
                                    </p>

                                  </div>
                                </div>

*/}

                                      <br/>
</>)}

                                  <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                    Autorització cessió del correu electrònic a l'AFA
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authAMPA" name="authAMPA" value={authAMPA} checked={authAMPA === "1" ? true : false } onChange={ (t) => { handleChangeAMPA(t.target.value) } } required/>
                                      <label htmlFor="authAMPA"> AUTORITZO que el centre faliciti el meu correu electrònic a l'AFA per poder rebre informació </label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authAMPA}</span>
                                    </p>

                                  </div>
                                </div>

                                      <br/>

                                <div className="row">
                                  <div className="col col-12 ">
                                    <h4>
                                      Declaració responsable de veracitat de les dades aportades 
                                    </h4>
                                    <p>
                                      <input type="checkbox" id="authResponsable" name="authResponsable" value={authResponsable} checked={authResponsable === "1" ? true : false } onChange={ (t) => { handleChangeResponsable(t.target.value) } } required/>
                                      <label htmlFor="authResponsable">Declaro que totes les dades aportades en aquest procediment són certes i la possibilitat d'acreditar documentalment totes les dades esmentades anteriorment, en cas que se m'exigeixin.</label>
                                      <span id="auhtMaterial_ERROR" className="a-Form-error">{errors.authResponsable}</span>
                                    </p>

                                  </div>
                                </div>


                  {/* Avís per als que han de fer 18 anys durant el curs */}
                  {props.dadesPersonals.edat = 17 && (
                    <>
                      <br />
                      <div className="row">
                        <div className="col col-12 ">
                          <h4>
                          Consentiment menors
                          </h4>
                          <p>
                          Les autoritzacions i el consentiment de seguiment tutorial per part de la família, implícit en el cas dels menors d'edat, tenen validesa durant tot el curs encara que l'alumne/a compleixi 18 anys durant el mateix. <br/>
                          L'alumne/a pot exercir el seu dret de majoria d'edat signant noves autoritzacions a través de la tutoria.
                          </p>

                        </div>
                      </div>

                      <br />
                    </>)}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>












                  <div className="container">
                            <div className="row">
                              <div className="col col-12" align="center">
                                <button onClick={validaAutoritzacions} className="button">SEGÜENT</button>
                              </div>
                            </div>
                          </div>

                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}