import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import 'react-slidedown/lib/slidedown.css'
import Dropdown from '../dropdown'
import { FormSelectYesNo,  FormInputText, DateSelector, FormTextArea } from './formelements'
import { useDropzone } from 'react-dropzone'
import axios from 'axios';
import config from '../../config'

const ContacteEmergencia = (props) => {

  return (<div className={'col ' + props.col}>
    <div className="t-Form-labelContainer">
      <label id="contacteEmergenciaNom" className="t-Form-label">{props.label}</label>
    </div>

    <FormInputText id="contacteEmergenciaNom" name="contacteEmergenciaNom" label="Nom i cognoms" col="col-12" value={props.values.contacteEmergenciaNom} obligatori={props.obligatori} size="30" maxLength="50" onChange={props.onChanges.nom} error={props.errors.contacteEmergenciaNom} />


<div className={ 'col col-12'}>
      <div id={props.id + '_CONTAINER'} className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">

      <div className="t-Form-labelContainer">
        <label  className="t-Form-label">Relació amb l'alumne { props.obligatori && (<span className="t-Form-required"> <span className="a-Icon">*</span> </span>)}</label>
      </div>

    <div className="t-Form-inputContainer">
      <div className="t-Form-itemWrapper">


        <select id="contacteEmergenciaRelacio" defaultValue="DNI" name="contacteEmergenciaRelacio" className="selectlist" size="1" onChange={(e) => props.onChanges.relacio(e.target.value)} required={props.obligatori}>
          <option value=""></option>
          <option value="Pare">Pare</option>
          <option value="Mare">Mare</option>
          <option value="Parella">Parella</option>
          <option value="Tutor centre">Tutor centre</option>
          <option value="Altres">Altres</option>
        </select>
      </div>
      <span id="contacteEmergencia_ERROR" className="a-Form-error">{props.errors.contacteEmergenciaRelacio}</span>
    </div>
      </div>
</div>

    <FormInputText id="contacteEmergenciaTelefon" name="contacteEmergenciaTelefon" label="Telèfon" col="col-12" value={props.values.contacteEmergenciaTelefon} obligatori={props.obligatori} size="30" maxLength="50" onChange={props.onChanges.telefon} error={props.errors.contacteEmergenciaTelefon} />

  </div>)
}


export default function EnrolmentFormPersonalDataId(props) {

  /* DADES GENERALS */
  const [ errors, setErrors ] = useState({});
  const [ errorValidacioIDdocument, setErrorValidacioIDdocument ] = useState();

  const handleErrors = (nom, error) => {
    //console.log('error', nom, error)
    setErrors(error)
  }

  const [dadesPersonals, setDadesPersonals ] = useState({validat: false});
  // gent que faci els 12 dins l'any - 1r ESO
  const any_12 = new Date().getFullYear() - 12
  let maxDate = new Date(any_12 + "-12-31")

  let minDate = new Date("1950-1-1")

  /* DADES PERSONALS */
  const [dadesIdentificativesOpen, setDadesIdentificativesOpen] = useState(true);
  const toggleOpen = () => {
    setDadesIdentificativesOpen(!dadesIdentificativesOpen);
  }

  const [nom, setNom] = useState('');
  const [cognom1, setCognom1] = useState('');
  const [cognom2, setCognom2] = useState('');
  const [tipusDoc, setTipusDoc] = useState('DNI');
  const [iddocument, setIDDocument] = useState('');
  const [iddocumentFrontFile, setIDDocumentFrontFile] = useState();
  const [iddocumentBackFile, setIDDocumentBackFile] = useState();
  const [fotoCarnetFile, setFotoCarnetFile] = useState();
  const [sexe, setSexe] = useState('');
  const [dataNaixement, setDataNaixement] = useState(maxDate)
  const [majorEdat, setMajorEdat] = useState();
  const [edat, setEdat] = useState();
  const [llibreFamiliaFile, setLlibreFamiliaFile] = useState();
  const [llibreFamiliaAlumneFile, setLlibreFamiliaAlumneFile] = useState();
  const [comunicacioProgenitors, setComunicacioProgenitors] = useState();

  const [progenitor1Nom, setProgenitor1Nom] = useState('');
  const [progenitor1iddocument, setProgenitor1iddocument] = useState('');
  const [progenitor1IDFrontFile, setProgenitor1IDFrontFile] = useState();
  const [progenitor1IDBackFile, setProgenitor1IDBackFile] = useState();
  const [progenitor1Telefon, setProgenitor1Telefon] = useState('');
  const [progenitor1Email, setProgenitor1Email] = useState('');
  const [progenitor1Observacions, setProgenitor1Observacions] = useState('');

  const [progenitor2Nom, setProgenitor2Nom] = useState();
  const [progenitor2iddocument, setProgenitor2iddocument] = useState();
  const [progenitor2IDFrontFile, setProgenitor2IDFrontFile] = useState();
  const [progenitor2IDBackFile, setProgenitor2IDBackFile] = useState();
  const [progenitor2Telefon, setProgenitor2Telefon] = useState();
  const [progenitor2Email, setProgenitor2Email] = useState('');
  const [progenitor2Observacions, setProgenitor2Observacions] = useState();

  const [contacteEmergenciaNom, setContacteEmergenciaNom] = useState('');
  const [contacteEmergenciaRelacio, setContacteEmergenciaRelacio] = useState();
  const [contacteEmergenciaTelefon, setContacteEmergenciaTelefon] = useState('');

  const [adreca, setAdreca] = useState('');
  const [codiPostal, setCodiPostal] = useState('');
  const [poblacio, setPoblacio] = useState('');
  const [provincia, setProvincia] = useState('');
  const [pais, setPais] = useState('Espanya');
  const [telefonFix, setTelefonFix] = useState('');
  const [telefonMobil, setTelefonMobil] = useState('');

  const [telefonFixObligatori, setTelefonFixObligatori] = useState(false);
  const [telefonMobilObligatori, setTelefonMobilObligatori] = useState(false);

  const [email, setEmail] = useState('');

  const [cursObligatori, setCursObligatori ] = useState();
  const [tornObligatori, setTornObligatori ] = useState();
  const [becat, setBecat] = useState();

  const [necessitatsEducativesEspecialsFile, setNecessitatsEducativesEspecialsFile] = useState();

  const handleBlurIDDocument = (e) => 
  {
    //let error = {};

    //TODO error.iddocument = validaIDDocument(e);

    validaIDDocument(e);

    //console.log("blur ID",error, e);
    //console.log("blur ID", e);

    return true;
  }



  /* DADES MEDIQUES */
  const [dadesMediquesOpen, setDadesMediquesOpen] = useState(false);
  const toggleDadesMediquesOpen = () => {
    setDadesMediquesOpen(!dadesMediquesOpen);
  }

  const [dadesMediques, setDadesMediques] = useState({validat: false});


  const [TSI, setTSI] = useState('');
  const [TSIFile, setTSIFile] = useState();
  const [nSS, setnSS] = useState('');
  const [allergies, setAllergies] = useState();
  const [allergiesFile, setAllergiesFile] = useState();
  const [allergiesObservacions, setAllergiesObservacions] = useState('');
  const [malalties, setMalalties] = useState();
  const [malaltiesFile, setMalaltiesFile] = useState();
  const [malaltiesObservacions, setMalaltiesObservacions] = useState('');
  const [problemesVista, setProblemesVista] = useState();
  const [problemesVistaFile, setProblemesVistaFile] = useState();
  const [problemesVistaObservacions, setProblemesVistaObservacions] = useState('');
  const [problemesMobilitat, setProblemesMobilitat] = useState();
  const [problemesMobilitatFile, setProblemesMobilitatFile] = useState();
  const [problemesMobilitatObservacions, setProblemesMobilitatObservacions] = useState('');
  const [medicacio, setMedicacio] = useState();
  const [medicacioFile, setMedicacioFile] = useState();
  const [medicacioObservacions, setMedicacioObservacions] = useState('');

  /* DADES LABORALS */
  const [dadesLaboralsOpen, setDadesLaboralsOpen] = useState(false);
  const toggleDadesLaboralsOpen = () => {
    setDadesLaboralsOpen(!dadesLaboralsOpen);
  }

  const [dadesLaborals, setDadesLaborals] = useState({validat: false});

  const [treballant, setTreballant] = useState();
  const [treballantFile, setTreballantFile] = useState();
  const [hasTreballatMai, setHasTreballatMai] = useState();
  const [hasTreballatMaiSector, setHasTreballatMaiSector] = useState();
  const [treballarasDurantCurs, setTreballarasDurantCurs] = useState();
  const [treballarasDurantCursHorari, setTreballarasDurantCursHorari] = useState();
  const [creusTreballarDificultaEstudis, setCreusTreballarDificultaEstudis] = useState();


  /* VALIDA DADES PERSONALS */
  const validaNom = () => {
    return nom.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaCognom1 = () => {
    return cognom1.length > 0 ? null : "El camp Primer Cognom no pot estar buit"
  }

  const validaSexe = () => {
    return sexe.length > 0 ? null : "Heu d'indicar un sexe"
  }

  const validaStringNoBuit = (camp, errormsg) => {
    return camp.length > 0 ? null : errormsg;
  }

  const validaAdreca = () => {
    return validaStringNoBuit(adreca, "El camp adreça no pot estar buit");
  }

  const validaCodiPostal = () => {
    return validaStringNoBuit(codiPostal, "El camp codi postal no pot estar buit");
  }

  const validaPoblacio = () => {
    return validaStringNoBuit(poblacio, "El camp població no pot estar buit");
  }

  const validaProvincia = () => {
    return validaStringNoBuit(provincia, "El camp provincia no pot estar buit");
  }

  const validaPais = () => {
    return validaStringNoBuit(pais, "El camp país no pot estar buit");
  }

  const validaIDDocumentDB = async (d) => {

  try 
   {

    const dadesPost = {
      cid: config.cid,
      act: 'chkiddoc',
      iddocument: d,
    }

    const headers = { 'Content-Type': 'multipart/form-data' }

      //axios.post(config.apiURL, dadesPost, headers)
      const response = await axios.post("/api/", dadesPost, headers);

      // si amb el DNI hi ha torn i curs obligatori els posem per a forcar la tria
      if(response.data.t) { setTornObligatori(response.data.t)} else { setTornObligatori()}
      if(response.data.c) { setCursObligatori(response.data.c)} else { setCursObligatori() }
      if(response.data.b) { setBecat(response.data.b)} else { setBecat()}

      setErrorValidacioIDdocument(response.data.r === 1 ? null : "Document no trobat al llistat de matriculables. Contacteu amb secretaria.")
      return false
    }
    catch (err) {
      //console.log(err)
      return false;
    };
    
  }


  const handleTelefonObligatori = ()  => {

    //console.log("TEL OBL")

    if(majorEdat) { 
      setTelefonFixObligatori(true)
      setTelefonMobilObligatori(true)

    //console.log("TEL OBL","MAJOR EDAT SI")

      // Si han omplert una de les dues l'altra no és obligatori
      if( telefonFix.length )
      {
        setTelefonMobilObligatori(false)
    //console.log("TEL OBL","FIX PLE MOB OPT")
      }

      if( telefonMobil.length )
      {
        setTelefonFixObligatori(false)
    //console.log("TEL OBL","MOBIL PLE FIX OPT")
      }

    } else {

    //console.log("TEL OBL","MAJOR EDAT NO")
      setTelefonMobilObligatori(false)
      setTelefonFixObligatori(false)
    }

  }


  const handleTelefonFix = (e) => {

    setTelefonFix(e)

  }

  const handleTelefonMobil= (e) => {

    setTelefonMobil(e)

  }

  useEffect(() => {

    // telefon obligatori per majors edat
    handleTelefonObligatori()

  }, [dataNaixement,telefonFix,telefonMobil])




  const validaIDDocument = (e) => {

      let error = {}
      // si tornem a comprovar eliminem error prèvi
    if (config.validaIDdocuments) {
      setErrorValidacioIDdocument("")
    }

  //console.log(":: -1",e)

    let d;

    if(e)
    {
      d = e.toUpperCase()
    }
    else
    {
      d = iddocument.toUpperCase()
    }

    
    if(!d.length > 0)
    {
      return "El camp no pot estar buit."
    }
    
    setIDDocument(d)

    if (tipusDoc === "DNI") {
      if(!/^[0-9]{8}[A-Z]$/.test(d)) 
      {
        return "El format de DNI no és vàlid."
      }
    }

    if (config.validaIDdocuments) {
      validaIDDocumentDB(d)
    }

    return null
  }

  const validaIDDocumentFrontFile = () => {

    return iddocumentFrontFile ? null : "El camp no pot estar buit."
  }

  const validaIDDocumentBackFile = () => {

    return iddocumentBackFile ? null : "El camp no pot estar buit."
  }

  const validaFotoCarnetFile = () => {

    // si no cal foto carnet valida sempre
    if(!config.fotoCarnet) return null

    return fotoCarnetFile ? null : "El camp no pot estar buit."
  }

  const validaProgenitor1Nom = () => {
    return progenitor1Nom.length > 0 ? null : "El camp no pot estar buit"
  }

  const validaProgenitor1iddocument= () => {
    return progenitor1iddocument.length > 0 ? null : "El camp no pot estar buit"
  }

  const validaProgenitor1FrontFile = () => {

    return progenitor1IDFrontFile ? null : "El camp no pot estar buit."
  }

  const validaProgenitor1BackFile = () => {

    return progenitor1IDBackFile ? null : "El camp no pot estar buit."
  }

  const validaProgenitor1Telefon = () => {
    return progenitor1Telefon.length > 0 ? null : "El camp no pot estar buit"
  }

  const validaProgenitor1Email = () => {
      return validaEmail(progenitor1Email);
    //return progenitor1Email.length > 0 ? null : "El camp no pot estar buit"
  }

  const validaProgenitor1Observacions = () => {
    return progenitor1Observacions.length > 0 ? null : "El camp no pot estar buit"
  }

  const validaProgenitor2Nom = () => {
    return progenitor1Nom.length > 0 ? null : "El nom no pot estar buit"
  }

  const validaProgenitor2Telefon = () => {
    return progenitor2Telefon.length > 0 ? null : "El camp no pot estar buit"
  }

  const validaProgenitor2Email = () => {

     // no obligatori pero si l'han posat comprovem format correcte
    if(progenitor2Email.length)
    {
      return validaEmail(progenitor2Email);
    }

    return null
  }

  const validaProgenitor2Observacions = () => {
    return progenitor2Observacions.length > 0 ? null : "El camp no pot estar buit"
  }

  const validaComunicacioProgenitors = () => {
    if (majorEdat) {
      return comunicacioProgenitors ? null : "Cal triar un valor"
    }

    return null
  }

  const validaContacteEmergenciaNom = () => {
    return contacteEmergenciaNom.length > 0 ? null : "El camp no pot estar buit"
  }

  const validaContacteEmergenciaRelacio = () => {
      return contacteEmergenciaRelacio ? null : "Cal triar un valor"
  }

  const validaContacteEmergenciaTelefon = () => {
    return contacteEmergenciaTelefon.length > 0 ? null : "El camp no pot estar buit"
  }


  const validaTelefons = () => {
    //if (majorEdat) {

      if( !telefonFix.length && !telefonMobil.length )
      {
        return "S'ha de proporcionar al menys un telèfon"
      }
    //}

    return null
  }

  const validaEmail = (e) => {

      if(!e.length)
      {
          return "El camp email no pot estar buit"
      }

      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if(!pattern.test(e))
      {
       return "No és un format d'email vàlid"
      }

    return null
  }



  const valida = (e) => {

    e.preventDefault();

    handleTelefonObligatori()

    let error = {}

    // abans de validar eliminem errors anteriors
    setErrors(error)

    //TODO ERROR
    error.iddocument = validaIDDocument()

    handleErrors('iddocument', error)

    error.nom = validaNom();

    handleErrors('nom', validaNom())

    error.cognom1 = validaCognom1();

    handleErrors('cognom1', error)

    handleErrors('dataNaixement', validaDataNaixement())

    error.telefon = validaTelefons();
    handleErrors('telefon', error)

    //if (majorEdat || email.length) {
      error.email = validaEmail(email);
      handleErrors('email', error)

    error.pais = validaPais();

    handleErrors('pais', error)

    error.provincia = validaProvincia();

    handleErrors('provincia', error)

    error.poblacio = validaPoblacio();

    handleErrors('poblacio', error)

    error.codiPostal = validaCodiPostal();

    handleErrors('codiPostal', error)

    error.adreca = validaAdreca();

    handleErrors('adreca', error)

    //errors.tipusDoc = validaTipusDoc(tipusDoc)


    if ( tipusDoc !== "PRE") {

      error.iddocumentFrontFile = validaIDDocumentFrontFile()

      handleErrors('iddocumentFrontFile', error)

      error.iddocumentBackFile = validaIDDocumentBackFile()
    }

    if (config.fotoCarnet) {
    handleErrors('fotoCarnetFile', error)

    error.fotoCarnetFile = validaFotoCarnetFile()
    }

    handleErrors('iddocumentBackFile', error)

    error.sexe = validaSexe(sexe)


    //console.log("CONTACTE",config.contacteEmergenciaObligatori)

    if ( config.contacteEmergenciaObligatori && majorEdat ) {

      error.contacteEmergenciaNom = validaContacteEmergenciaNom()
      handleErrors('contacteEmergenciaNom', error)

      error.contacteEmergenciaRelacio = validaContacteEmergenciaRelacio()
      handleErrors('contacteEmergenciaRelacio', error)

      error.contacteEmergenciaTelefon = validaContacteEmergenciaTelefon()
      handleErrors('contacteEmergenciaTelefon', error)

    }


    if (majorEdat) {

      error.comunicacioProgenitors = validaComunicacioProgenitors()

      handleErrors('comunicacioProgenitors', error)

    }
    else {

        error.progenitor1Nom = validaProgenitor1Nom()
        handleErrors('progenitor1Nom', error)

        error.progenitor1iddocument= validaProgenitor1iddocument()
        handleErrors('progenitor1iddocument', error)

        error.progenitor1IDFrontFile = validaProgenitor1FrontFile()
        handleErrors('progenitor1IDFrontFile', error)

        error.progenitor1IDBackFile = validaProgenitor1BackFile()
        handleErrors('progenitor1IDBackFile', error)

        error.progenitor1Telefon = validaProgenitor1Telefon()
        handleErrors('progenitor1Telefon', error)

        error.progenitor1Email = validaProgenitor1Email()
        handleErrors('progenitor1Email', error)

        error.progenitor1Observacions = validaProgenitor1Observacions()
        handleErrors('progenitor1Observacions', error)

        error.progenitor2Email = validaProgenitor2Email()
        handleErrors('progenitor2Email', error)

      }

    setErrors(error)

    //console.log('errors', errors)

    let noError = true;
    //DEVELOP if (window.location.hostname !== "localhost" ) 
    if(!config.develop) // bypass errors if develop == true
    {
     Object.keys(error).map( (k) => { if(error[k] !== null) noError = false } )
    }

    handleDadesPersonals(noError)

    if(noError)
    {

        if(!config.develop){ // bypass errors if develop == true
          if (config.validaIDdocuments) {
            //console.log("VALIDA",errorValidacioIDdocument);
              if(errorValidacioIDdocument !== null ) { 
              // console.log("NO VALIDAT"); 
                return false;}
          }
        }

//      console.log("VALIDAT",error)

        setDadesIdentificativesOpen(false)
        setDadesMediquesOpen(true)
        setDadesLaboralsOpen(false)
        if(edat<16)
        {
          handleDadesLaborals(true)
        }
    }

    return false;
  }


  const handleFileError = (e,msg) => {
    let err  = {...errors}
    err[e] = msg 
    setErrors(err)
  }



  /* VALIDA DADES MEDIQUES */
 const  validaTSI  = () => { return TSI.length > 0 ? null : "El camp no pot estar buit" }
 const  validaTSIFile  = () => { return TSIFile ? null : "El camp no pot estar buit." }

 const  validanSS  = () => { return nSS.length > 0 ? null : "El camp no pot estar buit" }
 
 const  validaAllergies  = () => { return allergies ? null : "Heu triar un valor" }
 const  validaAllergiesFile  = () => { if(allergies === "1" && config.adjuntMedicObligatori ) { return allergiesFile ? null : "Heu triar un valor" } return null }
 const  validaAllergiesObservacions  = () => { if(allergies === "1") { return allergiesObservacions.length > 0 ? null : "El camp no pot estar buit" } return null }
 
 const  validaMalalties  = () => { return malalties ? null : "Heu triar un valor" }
 const  validaMalaltiesFile  = () => { if( malalties === "1" && config.adjuntMedicObligatori ) { return malaltiesFile ? null : "Heu d'adjuntar un arxiu" } return null }
 const  validaMalaltiesObservacions  = () => { if( malalties === "1") { return malaltiesObservacions.length > 0 ? null : "El camp no pot estar buit" } return null }
 
 const  validaProblemesVista  = () => { return problemesVista ? null : "Heu triar un valor" }
 const  validaProblemesVistaFile  = () => { if( problemesVista === "1" && config.adjuntMedicObligatori ) { return problemesVistaFile ? null : "Heu d'adjuntar un arxiu" } return null }
 const  validaProblemesVistaObservacions  = () => { if( problemesVista === "1") { return problemesVistaObservacions.length > 0 ? null : "El camp no pot estar buit" } return null }
 
 const  validaProblemesMobilitat  = () => { return problemesMobilitat ? null : "Heu triar un valor" }
 const  validaProblemesMobilitatFile  = () => { if( problemesMobilitat === "1" && config.adjuntMedicObligatori ) { return problemesMobilitatFile ? null : "Heu d'adjuntar un arxiu" } return null }
 const  validaProblemesMobilitatObservacions  = () => { if( problemesMobilitat === "1") { return problemesMobilitatObservacions.length > 0 ? null : "El camp no pot estar buit" } return null }
 
 const  validaMedicacio  = () => { return medicacio ? null : "Heu triar un valor" }
 const  validaMedicacioFile  = () => { if( medicacio === "1" && config.adjuntMedicObligatori ) { return medicacioFile ? null : "Heu d'adjuntar un arxiu" } return null }
 const  validaMedicacioObservacions  = () => { if( medicacio === "1") { return medicacioObservacions.length > 0 ? null : "Heu triar un valor" } return null }


const validaMediques = (e) => {
  e.preventDefault();

  let error = {}

  error.TSI = validaTSI();
  handleErrors('TSI',error)

  error.TSIFile = validaTSIFile()
  handleErrors('TSIFile', error)

 /* no és obligatori
  error.nSS = validanSS()
  handleErrors('nSS', error)
 */

  error.allergies = validaAllergies()
  handleErrors('allergies', error)

  error.allergiesFile = validaAllergiesFile()
  handleErrors('allergiesFile', error)

  error.allergiesObservacions = validaAllergiesObservacions()
  handleErrors('allergiesObservacions', error)

  error.malalties = validaMalalties()
  handleErrors('malalties', error)

  error.malaltiesFile = validaMalaltiesFile()
  handleErrors('malaltiesFile', error)

  error.malaltiesObservacions = validaMalaltiesObservacions()
  handleErrors('malaltiesObservacions', error)

  error.problemesVista = validaProblemesVista()
  handleErrors('problemesVista', error)

  error.problemesVistaFile = validaProblemesVistaFile()
  handleErrors('problemesVistaFile', error)

  error.problemesVistaObservacions = validaProblemesVistaObservacions()
  handleErrors('problemesVistaObservacions', error)

  error.problemesMobilitat = validaProblemesMobilitat()
  handleErrors('problemesMobilitat', error)

  error.problemesMobilitatFile = validaProblemesMobilitatFile()
  handleErrors('problemesMobilitatFile', error)

  error.problemesMobilitatObservacions = validaProblemesMobilitatObservacions()
  handleErrors('problemesMobilitatObservacions', error)

  error.medicacio = validaMedicacio()
  handleErrors('medicacio', error)

  error.medicacioFile = validaMedicacioFile()
  handleErrors('medicacioFile', error)

  error.medicacioObservacions = validaMedicacioObservacions()
  handleErrors('medicacioObservacions', error) 

  setErrors(error)

  let noError = true;
  //DEVELOP if (window.location.hostname !== "localhost" ) Object.keys(error).map( (k) => { if(error[k] !== null) noError = false } )
  if(!config.develop) // bypass errors if develop == true
  {
    Object.keys(error).map( (k) => { if(error[k] !== null) noError = false } )
  }

  //console.log('ERROR',error);

  handleDadesMediques(noError)

  if(noError)
  {
      setDadesIdentificativesOpen(false)
      setDadesMediquesOpen(false)
      if(edat>=16)
      {
        setDadesLaboralsOpen(true)
      }
      else
      {
          // #NOTREBALLA : ALUMNES ESO I MENORS DE 16 EN GENERAL NO PODEN HAVER TREBALLAT SALTEM APARTAT
        setDadesLaboralsOpen(false)
        handleDadesLaborals(true)
      }
  }

  return false;
}

  /* VALIDA DADES LABORALS */

   const validaTreballant = () => { return treballant ? null : "Heu triar un valor" }
   const validaTreballantFile  = () => { if( treballant === "1") { return treballantFile ? null : "Heu d'adjuntar un arxiu" } return null }
   const validaHasTreballatMai = () => { return hasTreballatMai ? null : "Heu triar un valor" }
   const validaTreballarasDurantCurs = () => { return treballarasDurantCurs ? null : "Heu triar un valor" }
   const validaCreusTreballarDificultaEstudis = () => { return creusTreballarDificultaEstudis ? null : "Heu triar un valor" }

  const validaLaborals = (e) => {
    e.preventDefault();

    let error = {}

    error.treballant = validaTreballant()
    handleErrors('treballant', error)

    if (config.treballantFile) {
      error.treballantFile = validaTreballantFile()
      handleErrors('treballantFile', error)
    }

    error.hasTreballatMai = validaHasTreballatMai()
    handleErrors('hasTreballatMai', error)

    error.treballarasDurantCurs = validaTreballarasDurantCurs()
    handleErrors('treballarasDurantCurs', error)

    error.creusTreballarDificultaEstudis = validaCreusTreballarDificultaEstudis()
    handleErrors('creusTreballarDificultaEstudis', error)


    setErrors(error)

    let noError = true;
    //DEVELOP if (window.location.hostname !== "localhost" ) Object.keys(error).map( (k) => { if(error[k] !== null) noError = false } )
    if(!config.develop) // bypass errors if develop == true
    {
     Object.keys(error).map( (k) => { if(error[k] !== null) noError = false } )
    }

    //console.log('ERROR',error);

    handleDadesLaborals(noError)

    if(noError)
    {
        setDadesIdentificativesOpen(false)
        setDadesMediquesOpen(false)
        setDadesLaboralsOpen(false)
    }

    return false;
  }


  useEffect(() => {

    let error = {}

    console.log("DATA_NAIXE")

    //console.log('valida data naixement')
    error.dataNaixement = validaDataNaixement();

    handleErrors('dataNaixement', error)

    let dadesPersonals = { ...props.dadesPersonals }

    dadesPersonals.dataNaixement = dataNaixement;

    props.handleDadesPersonals(dadesPersonals)

  }, [dataNaixement])


  const handleDadesPersonals = (noError) => {

  let d = {
  "validat": noError,
  "nom": nom , 
  "cognom1": cognom1 , 
  "cognom2": cognom2 , 
  "tipusDoc": tipusDoc , 
  "iddocument": iddocument , 
  "iddocumentFrontFile": iddocumentFrontFile , 
  "iddocumentBackFile": iddocumentBackFile , 
  "fotoCarnetFile": fotoCarnetFile, 
  "sexe": sexe , 
  "dataNaixement": dataNaixement , 
  "majorEdat": majorEdat , 
  "edat": edat, 
  "llibreFamiliaFile": llibreFamiliaFile , 
  "llibreFamiliaAlumneFile": llibreFamiliaAlumneFile , 
  "comunicacioProgenitors": comunicacioProgenitors , 

  "progenitor1Nom": progenitor1Nom , 
  "progenitor1iddocument": progenitor1iddocument, 
  "progenitor1IDFrontFile": progenitor1IDFrontFile , 
  "progenitor1IDBackFile": progenitor1IDBackFile , 
  "progenitor1Telefon": progenitor1Telefon , 
  "progenitor1Email": progenitor1Email , 
  "progenitor1Observacions": progenitor1Observacions , 

  "progenitor2Nom": progenitor2Nom , 
  "progenitor2iddocument": progenitor2iddocument, 
  "progenitor2IDFrontFile": progenitor2IDFrontFile , 
  "progenitor2IDBackFile": progenitor2IDBackFile , 
  "progenitor2Telefon": progenitor2Telefon , 
  "progenitor2Email": progenitor2Email , 
  "progenitor2Observacions": progenitor2Observacions , 

  "contacteEmergenciaNom": contacteEmergenciaNom , 
  "contacteEmergenciaRelacio": contacteEmergenciaRelacio , 
  "contacteEmergenciaTelefon": contacteEmergenciaTelefon , 

  "adreca": adreca , 
  "codiPostal": codiPostal , 
  "poblacio": poblacio , 
  "provincia": provincia , 
  "pais": pais , 
  "telefonFix": telefonFix , 
  "telefonMobil": telefonMobil , 
  "email": email, 

  "tornObligatori": tornObligatori, 
  "cursObligatori": cursObligatori, 
  "becat": becat, 

  "necessitatsEducativesEspecialsFile": necessitatsEducativesEspecialsFile
  }

    //console.log("DADES_PERSONALS",d)
    setDadesPersonals(d)
    props.handleDadesPersonals(d)
  }

  const handleDadesMediques = (noError) => {

  let d = {
   "validat": noError,
  /* DADES MEDIQUES */

  "TSI": TSI , 
  "TSIFile": TSIFile , 
  "nSS": nSS , 
  "allergies": allergies , 
  "allergiesFile": allergiesFile , 
  "allergiesObservacions": allergiesObservacions , 
  "malalties": malalties , 
  "malaltiesFile": malaltiesFile , 
  "malaltiesObservacions": malaltiesObservacions , 
  "problemesVista": problemesVista , 
  "problemesVistaFile": problemesVistaFile , 
  "problemesVistaObservacions": problemesVistaObservacions , 
  "problemesMobilitat": problemesMobilitat , 
  "problemesMobilitatFile": problemesMobilitatFile , 
  "problemesMobilitatObservacions": problemesMobilitatObservacions , 
  "medicacio": medicacio , 
  "medicacioFile": medicacioFile , 
  "medicacioObservacions": medicacioObservacions  
  }

    //console.log("DADES_MEDIQUES",d)
    setDadesMediques(d)
    props.handleDadesMediques(d)
  }

  const handleDadesLaborals = (noError) => {

  let d = {
   "validat": noError,

  /* DADES LABORALS */
  "treballant": treballant , 
  "treballantFile": treballantFile , 
  "hasTreballatMai": hasTreballatMai , 
  "hasTreballatMaiSector": hasTreballatMaiSector , 
  "treballarasDurantCurs": treballarasDurantCurs , 
  "treballarasDurantCursHorari": treballarasDurantCursHorari , 
  "creusTreballarDificultaEstudis": creusTreballarDificultaEstudis } 

    //console.log("DADES_LABORALS",d)
    setDadesLaborals(d)
    props.handleDadesLaborals(d)
  }


// VALIDA 

  const calculaEdat = (dataNaixement) => {
        const birthDate = new Date(dataNaixement);
        const currentDate = new Date();
    
        let edat = currentDate.getFullYear() - birthDate.getFullYear();

        const monthDifference = currentDate.getMonth() - birthDate.getMonth();

        //console.log("EDAT CALC",monthDifference,edat,currentDate.getFullYear(),birthDate.getFullYear())
        if (
          monthDifference < 0 ||
          (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())
        ) {
          edat--;
        }
    
        //console.log("EDAT CALC 4",edat);
        return edat;
  }

  const validaDataNaixement = () => {
    //let temps = new Date(dataNaixement)


    if (dataNaixement) 
    {
      const edatCalc = calculaEdat(dataNaixement)

    //console.log("VALIDA DATA_NAIXE",dataNaixement,edatCalc,edatCalc >= 18)

      setEdat( edatCalc )

      setMajorEdat(edatCalc >= 18)

      if(edatCalc<16)
      {
       handleDadesLaborals(true)
      }

     // temps.setFullYear(temps.getFullYear() + 18) // 18 anys
     //setMajorEdat(new Date().getTime() >= temps.getTime())

    //console.log("EDAT",edat,edatCalc,majorEdat)
    }

    // data ok
    return null;
    // return /^[0-3][0-9]\/[0-1][0-9]\/[0-9]{4}$/.test(dataNaixement) ? null : "El format de la data no és vàlid."
  }


  function DropZone(props) {
    const onDrop = useCallback(acceptedFiles => {
      // Do something with the files

      props.handleFileError(null)

      let file = acceptedFiles[0];

      const MAXSIZE = 4
      if (file.size > MAXSIZE * 1024 * 1024 ) {
        props.handleFileError("Arxiu massa gran màxim " + MAXSIZE + "MB")
        file = undefined
        props.onChange() // buidem file al no ser correcte
      }

      if (file) {

        //console.log("FT",file.type);
        if (!/^image\/png/.test(file.type) && !/^image\/jpeg/.test(file.type)) {
          file = undefined
          props.onChange() // buidem file al no ser correcte
          props.handleFileError("Tipus d'arxiu no vàlid. S'accepten imatges JPG o PNG.")
          return false
        }

        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          // Do whatever you want with the file contents
          const fileContent = reader.result
          //console.log("BIN", fileContent)

          props.onChange({file: file, fileContent: fileContent})

          //console.log(acceptedFiles[0].name) // size type
        }
        reader.readAsDataURL(file)
      }

    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
      <div className={ 'col ' + props.col}>
        <div className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs" id={props.id + '_CONTAINER'}>
          <div className="t-Form-labelContainer">
            <label htmlFor={props.id} id={props.id + '_LABEL'} 
              className="t-Form-label">{props.label} <small>{props.labelSmall}{ props.obligatori ? (<sup>*</sup>) : "" }</small></label>
          </div>
          <div className="t-Form-inputContainer">
            <div className="t-Form-itemWrapper">
              <div {...getRootProps()} style={{ width: '100%' }}>
                <input {...getInputProps()} />
                {isDragActive && <p>Drop the files here ...</p>}
                {!isDragActive && (<div className="dropZone"> {props.file?.file ? props.file.file.name : props.labelDrop}</div>)}
              </div>
            </div>
            <span id={props.id + '_ERROR'} className="a-Form-error">{props.error}</span>
          </div>
        </div>
      </div>)
  }


  return (

    <div className="container">
      <div className="row">
        <div className="col col-12 ">
            { config.pagamentTPV && ( dadesIdentificativesOpen && (
            <div style={{ textAlign: 'center', fontSize: '1.05rem'}}>Si ja heu realitzat la inscripció prèviament i només voleu realitzar el pagament podeu accedir-hi directament aquí <a href={config.urlTPV}>{config.urlTPV}</a></div>
            ))}
          <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
            <div className="t-Region-header">
              <div className="t-Region-headerItems  t-Region-headerItems--controls">
                <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={toggleOpen}>
                  {dadesIdentificativesOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                  {!dadesIdentificativesOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                </button>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--title">
                <h2 className="t-Region-title a-Collapsible-heading">Dades personals</h2>
              </div>
              <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
            </div>
            <div className="t-Region-bodyWrap">

              <Dropdown open={dadesIdentificativesOpen}>
                <div className="t-Region-body a-Collapsible-content">
                  <div className="container">

                    <div className="row">
                      <div className="col col-12" >
                          <label className="t-Form-label">Dades de l'alumne/a:</label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col col-12 ">
                        <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                          <div className="t-Region-bodyWrap">
                            <div className="t-Region-body">
                              <div className="container">
                                <div className="row">
                                  <div className="col col-3 ">
                                    <div
                                      className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs" id="tipusDoc_CONTAINER">
                                      <div className="t-Form-labelContainer">
                                        <label htmlFor="tipusDoc" id="tipusDoc_LABEL"
                                          className="t-Form-label">Tipus document:</label>
                                      </div>
                                      <div className="t-Form-inputContainer">
                                        <div className="t-Form-itemWrapper">

                                          <select id="tipusDoc" defaultValue="DNI" name="tipusDoc" className="selectlist" size="1" onChange={(e) => setTipusDoc(e.target.value)}>
                                            <option value="DNI">DNI - Document Nacional d'Identitat </option>
                                            <option value="NIE">NIE - Número d'Identificació d'Estranger</option>
                                            <option value="PASS">PASS - Passaport/Document d'un país de la comunitat Europea</option>
                                            { config.tipusDocTSI && (<option value="TSI">TSI - Targeta sanitària individual</option>) }
                                            { config.tipusDocPreInscripcio && (<option value="PRE">PRE - N. Preinscripció</option>) }
                                          </select></div>
                                        <span className="a-Form-error">{errors.tipusDoc}</span>
                                      </div>
                                    </div>
                                  </div>


                                  <FormInputText id="iddocument" name="iddocument" col="col-3" label="Número document" obligatori={true} size="20" maxLength="20" value={iddocument} onBlur={handleBlurIDDocument} onChange={setIDDocument} error={ [errors.iddocument,errorValidacioIDdocument].join(' ').trim() } />

                                  { tipusDoc !== "PRE" && (
                                    <>
                                      <DropZone id="iddocumentFrontFile" file={iddocumentFrontFile} col="col-2" onChange={setIDDocumentFrontFile} label="Adjuntar document*" labelSmall="(cara davant)" labelDrop="adjuntar imatge" error={errors.iddocumentFrontFile} handleFileError={(msg) => handleFileError('iddocumentFrontFile', msg)} />

                                      <DropZone id="iddocumentBackFile" file={iddocumentBackFile} col="col-2" onChange={setIDDocumentBackFile} label="Adjuntar document*" labelSmall="(cara darrera)" labelDrop="adjuntar imatge" error={errors.iddocumentBackFile} handleFileError={(msg) => handleFileError('iddocumentBackFile', msg)} />
                                    </>
                                  )}

                                 { config.fotoCarnet && (<DropZone id="fotoCarnetFile" file={fotoCarnetFile} col="col-2" onChange={setFotoCarnetFile} label="Adjuntar foto mida carnet*" labelSmall="" labelDrop="adjuntar imatge" error={errors.fotoCarnetFile} handleFileError={ (msg) =>handleFileError('fotoCarnetFile',msg) } />)}

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>

                    <div className="row">
                      <div className="col col-12 ">
                        <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding margin-top-sm">


                          <div className="t-Region-bodyWrap">
                            <div className="t-Region-body">
                              <div className="container">

                                <div className="row">

                                  <FormInputText id="nom" name="nom" col="col-3" label="Nom" value={nom} obligatori={true} size="30" maxLength="50" onChange={setNom} valida={validaNom} error={errors.nom} />

                                  <FormInputText id="cognom1" name="cognom1" col="col-3" label="Primer cognom" value={cognom1} obligatori={true} size="30" maxLength="50" onChange={setCognom1} valida={validaCognom1} error={errors.cognom1} />

                                  <FormInputText id="cognom2" name="cognom2" col="col-3" label="Segon cognom" value={cognom2} obligatori={false} size="30" maxLength="50" onChange={setCognom2} error={errors.cognom2} />

                                  <div className="col col-1 ">
                                    <div className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs" id="sexe_CONTAINER">
                                      <div className="t-Form-labelContainer">
                                        <label htmlFor="sexe" id="sexe_LABEL" className="t-Form-label">Sexe: </label>
                                        <span className="t-Form-required">
                                          <span className="a-Icon">*</span>
                                        </span>
                                      </div>

                                      <div className="t-Form-inputContainer">
                                        <div className="t-Form-itemWrapper">
                                          <select id="sexe" name="sexe" defaultValue={sexe} className="selectlist" size="1" onChange={(e) => setSexe(e.target.value)}>
                                            <option value=""></option>
                                            <option value="D" >Dona</option>
                                            <option value="H" >Home</option>
                                            { config.sexeNoBinari && (<option value="NB" >No-binari</option>)}
                                          </select></div>
                                        <span id="sexe_ERROR" className="a-Form-error">{errors.sexe}</span>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="col col-2 ">
                                    <div id="dataNaixement" className="t-Form-fieldContainer t-Form-fieldContainer--stacked t-Form-fieldContainer--stretchInputs">
                                      <div className="t-Form-labelContainer">
                                        <label htmlFor="dataNaixement" id="dataNaixement_LABEL" className="t-Form-label">Data Naixement (d/m/a): </label>
                                        <span className="t-Form-required">
                                          <span className="a-Icon">*</span>
                                        </span>
                                      </div>

                                      <div className="t-Form-inputContainer">
                                        <div className="t-Form-itemWrapper">
                                          <DateSelector selected={dataNaixement} onChange={setDataNaixement} minDate={minDate} maxDate={maxDate} />
                                        </div>

                                        <span id="dataNaixement_ERROR" className="a-Form-error">{errors.dataNaixement}</span>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className="row">

                                  {majorEdat === true && (
                                  <React.Fragment>

                                    <FormSelectYesNo id="autoritzoComunicacioProgenitors"
                                      name="autoritzoComunicacioProgenitors"
                                      col="col-5"
                                      value={comunicacioProgenitors}
                                      label="Autoritzo que els meus progenitors/tutors legals puguin rebre comunicats de l’institut."
                                      obligatori={true}
                                      error={errors.comunicacioProgenitors}
                                      handleChange={(e) => setComunicacioProgenitors(e.target.value)} />


                                    <ContacteEmergencia col="col-4"
                                      label="Telèfon en cas d'emergència"
                                      values={{ nom: contacteEmergenciaNom, relacio: contacteEmergenciaRelacio, telefon: contacteEmergenciaTelefon }}
                                      onChanges={{ nom: setContacteEmergenciaNom, relacio: setContacteEmergenciaRelacio, telefon: setContacteEmergenciaTelefon }}
                                      obligatori={ config.contacteEmergenciaObligatori && majorEdat }
                                      errors={errors} />

                                  </React.Fragment>)
                                  }

                                  {majorEdat === false &&

                                    (<React.Fragment>
                                      <DropZone id="llibreFamiliaFile" file={llibreFamiliaFile} onChange={setLlibreFamiliaFile} label="Llibre de família" labelSmall="(plana de progenitors)" labelDrop="adjuntar imatge" error={errors.llibreFamiliaFile}  handleFileError={ (msg) =>handleFileError('llibreFamiliaFile',msg) }  />

                                      <DropZone id="llibreFamiliaAlumneFile" file={llibreFamiliaAlumneFile} onChange={setLlibreFamiliaAlumneFile} label="Llibre de família" labelSmall="(plana de l'alumne/a)" labelDrop="adjuntar imatge" error={errors.llibreFamiliaAlumneFile}  handleFileError={ (msg) =>handleFileError('llibreFamiliaAlumneFile',msg) }  />
                                      <div className="col col-3"><small>En cas de alumnat estranger sense llibre de família, adjuntar documentació pertinent.</small></div>

                                    <div className="row">
                                      <div className="col col-12" >
                                        &nbsp;
                                      </div>
                                    </div>


                                      <div className="row">
                                        <div className="col col-12" >
                                          <div className="t-Form-labelContainer">
                                            <label id="progenitor1Nom_LABEL" htmlFor="progenitor1Nom" className="t-Form-label">Dades dels progenitors / Tutors legals</label>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">

                                        <div className="col col-12" >

                                          <div className="col col-4">
                                            <div className="t-Form-labelContainer">
                                              <label id="progenitor1Nom_LABEL" htmlFor="progenitor1Nom" className="t-Form-label">Tutor legal/Progenitor 1*</label>
                                            </div>

                                            <FormInputText id="progenitor1Nom" name="progenitor1Nom" col="col-12" label="Nom i cognoms" value={progenitor1Nom} obligatori={true} size="30" maxLength="50" onChange={setProgenitor1Nom} error={errors.progenitor1Nom} />
                                            <FormInputText id="progenitor1iddocument" name="progenitor1iddocument" col="col-6" label="DNI/NIE/Passaport" value={progenitor1iddocument} obligatori={true} size="20" maxLength="20" onChange={setProgenitor1iddocument} error={errors.progenitor1iddocument} />
                                             <div className="col col-12"></div>
                                            <DropZone id="progenitor1IDFrontFile" file={progenitor1IDFrontFile} onChange={setProgenitor1IDFrontFile} label="DNI/NIE/Passaport (davant)*" labelDrop="adjuntar imatge" error={errors.progenitor1IDFrontFile}  handleFileError={ (msg) =>handleFileError('progenitor1IDFrontFile',msg) } />
                                            <DropZone id="progenitor1IDBackFile" file={progenitor1IDBackFile} onChange={setProgenitor1IDBackFile} label="DNI/NIE/Passaport (darrere)*" labelDrop="adjuntar imatge" error={errors.progenitor1IDBackFile}  handleFileError={ (msg) =>handleFileError('progenitor1IDBackFile',msg) } />
                                            <FormInputText id="progenitor1Telefon" name="progenitor1Telefon" col="col-12" label="Telèfon" value={progenitor1Telefon} obligatori={true} size="30" maxLength="50" onChange={setProgenitor1Telefon} error={errors.progenitor1Telefon} />
                                            <FormInputText id="progenitor1Email" name="progenitor1Email" col="col-12" label="E-mail" value={progenitor1Email} obligatori={true} size="30" maxLength="50" onChange={setProgenitor1Email} error={errors.progenitor1Email} />
                                            <FormTextArea id="progenitor1Observacions" name="progenitor1Observacions" label="Horari de contacte / Observacions" value={progenitor1Observacions} obligatori={true} rows="5" cols="80" maxLength="1000" onChange={setProgenitor1Observacions} error={errors.progenitor1Observacions} />
                                          </div>

                                          <div className="col col-4">
                                            <div className="t-Form-labelContainer">
                                              <label id="progenitor2Nom_LABEL" htmlFor="progenitor2Nom" className="t-Form-label">Tutor legal/Progenitor 2</label>
                                            </div>

                                            <FormInputText id="progenitor2Nom" name="progenitor2Nom" col="col-12" label="Nom i cognoms" value={progenitor2Nom} obligatori={false} size="30" maxLength="50" onChange={setProgenitor2Nom} error={errors.progenitor2Nom} />
                                            <FormInputText id="progenitor2iddocument" name="progenitor2iddocument" col="col-6" label="DNI/NIE/Passaport" value={progenitor2iddocument} obligatori={false} size="20" maxLength="20" onChange={setProgenitor2iddocument} error={errors.progenitor2iddocument} />
                                             <div className="col col-12"></div>
                                            <DropZone id="progenitor2IDFrontFile" file={progenitor2IDFrontFile} onChange={setProgenitor2IDFrontFile} label="DNI/NIE/Passaport (davant)" labelDrop="adjuntar imatge" error={errors.progenitor2IDFrontFile}  handleFileError={ (msg) =>handleFileError('progenitor2IDFrontFile',msg) }  />
                                            <DropZone id="progenitor2IDBackFile" file={progenitor2IDBackFile} onChange={setProgenitor2IDBackFile} label="DNI/NIE/Passaport (darrere)" labelDrop="adjuntar imatge" error={errors.progenitor2IDBackFile}  handleFileError={ (msg) =>handleFileError('progenitor2IDBackFile',msg) }  />
                                            <FormInputText id="progenitor2Telefon" name="progenitor2Telefon" col="col-12" label="Telèfon" value={progenitor2Telefon} obligatori={false} size="30" maxLength="50" onChange={setProgenitor2Telefon} error={errors.progenitor2Telefon} />
                                            <FormInputText id="progenitor2Email" name="progenitor2Email" col="col-12" label="E-mail" value={progenitor2Email} obligatori={false} size="30" maxLength="50" onChange={setProgenitor2Email} error={errors.progenitor2Email} />
                                            <FormTextArea id="progenitor2Observacions" name="progenitor2Observacions" label="Horari de contacte / Observacions" value={progenitor2Observacions} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setProgenitor2Observacions} error={errors.progenitor2Observacions} />

                                          </div>


                                            { console.log("CE OBL",config.contacteEmergenciaObligatori && majorEdat, config.contacteEmergenciaObligatori, majorEdat, edat)  }

                                          <ContacteEmergencia col="col-4"
                                            label="Telèfon en cas d'emergència"
                                            values={{ nom: contacteEmergenciaNom, relacio: contacteEmergenciaRelacio, telefon: contacteEmergenciaTelefon }}
                                            onChanges={{ nom: setContacteEmergenciaNom, relacio: setContacteEmergenciaRelacio, telefon: setContacteEmergenciaTelefon }}
                                            errors={errors} />

                                        </div>

                                      </div>
                                    </React.Fragment>)}

                                </div>

                                <div className="row">

                                  <FormInputText id="telefonmobil" name="telefonmobil" col="col-3" label="Telèfon mòbil alumne/a" value={telefonMobil} obligatori={true || telefonMobilObligatori} size="10" maxLength="10" onChange={handleTelefonMobil} error={errors.telefon} />
                                  <FormInputText id="telefonfix" name="telefonfix" col="col-3" label="Telèfon fix alumne/a" value={telefonFix} obligatori={true || telefonFixObligatori} size="10" maxLength="10" onChange={handleTelefonFix} error={errors.telefon} />
                                  <FormInputText id="email" name="email" col="col-3" label="Email alumne/a" value={email} obligatori={true || majorEdat} size="10" maxLength="50" onChange={setEmail} error={errors.email} />
                                </div>

                                <div className="row">

                                  <FormInputText id="adreca" name="adreca" col="col-5" label="Adreça habitual" value={adreca} obligatori={true} size="30" maxLength="50" onChange={setAdreca} error={errors.adreca} />
                                  <FormInputText id="codipostal" name="codipostal" col="col-1" label="Codi postal" value={codiPostal} obligatori={true} size="10" maxLength="10" onChange={setCodiPostal} error={errors.codiPostal} />

                                </div>
                                <div className="row">

                                  <FormInputText id="poblacio" name="poblacio" col="col-3" label="Població" value={poblacio} obligatori={true} size="20" maxLength="50" onChange={setPoblacio} error={errors.poblacio} />
                                  <FormInputText id="provincia" name="provincia" col="col-3" label="Provincia" value={provincia} obligatori={true} size="10" maxLength="10" onChange={setProvincia} error={errors.provincia} />
                                  <FormInputText id="pais" name="pais" col="col-3" label="País" value={pais} obligatori={true} size="10" maxLength="10" onChange={setPais} error={errors.pais} />

                                </div>

                                { config.necessitatsEducativesEspecials && (<div className="row">
                                  <DropZone id="necessitatsEducativesEspecialsFile" file={necessitatsEducativesEspecialsFile} col="col-3" onChange={setNecessitatsEducativesEspecialsFile} label="Estudiant amb necessitats educatives especials:" labelSmall="" labelDrop="adjuntar documentació acreditativa corresponent" error={errors.necessitatsEducativesEspecialsFile} handleFileError={ (msg) =>handleFileError('necessitatsEducativesEspecialsFile',msg) } />
                                </div>) }

                              </div>

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="row">
                      <div className="col col-12" align="center">
                        { 
                          /* si cal validar documents deshabilitem botó continuar fins assegurar que està validat correctament */
                        
                        ((config.validaIDdocuments && ( errorValidacioIDdocument === null || errorValidacioIDdocument === "")  ) 
                        || !config.validaIDdocuments ) ?
                        (<button onClick={valida} className="button">SEGÜENT</button>)
                        :
                        (<button className="button" disabled>SEGÜENT</button>)}
                      </div>
                    </div>
                  </div>

                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>





      {/* DADES MEDIQUES */}

      { dadesPersonals.validat && (
      <div className="container">
        <div className="row">
          <div className="col col-12 ">
            <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
              <div className="t-Region-header">
                <div className="t-Region-headerItems  t-Region-headerItems--controls">
                  <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={toggleDadesMediquesOpen}>
                    {dadesMediquesOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                    {!dadesMediquesOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                  </button>
                </div>
                <div className="t-Region-headerItems t-Region-headerItems--title">
                  <h2 className="t-Region-title a-Collapsible-heading">Dades mèdiques</h2>
                </div>
                <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
              </div>
              <div className="t-Region-bodyWrap">

                <Dropdown open={dadesMediquesOpen}>
                  <div className="t-Region-body a-Collapsible-content">

                    <div className="container">
                      <div className="row">
                        <div className="col col-12 ">
                          <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                            <div className="t-Region-bodyWrap">
                              <div className="t-Region-body">
                                <div className="container">
                                  <div className="row">


                                    <FormInputText id="TSI" name="TSI" col="col-3" label="Targeta Sanitaria Individual (TSI)" obligatori={true} size="20" maxLength="15" value={TSI} onChange={setTSI} error={errors.TSI} />

                                    <DropZone id="TSIFile" file={TSIFile} onChange={setTSIFile} label="Adjuntar TSI (davant)" obligatori={true} labelDrop="adjuntar document" error={errors.TSIFile}  handleFileError={ (msg) =>handleFileError('TSIFile',msg) }  />

                                    <div className="col col-1">&nbsp;</div>

                                    <FormInputText id="nSS" name="nSS" col="col-3" label="Nº Seguretat Social" obligatori={false} size="20" maxLength="15" value={nSS} onChange={setnSS} error={errors.nSS} />

                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="allergies"
                                        name="allergies"
                                        col="col-3"
                                        label="Al·lèrgies o intoleràncies"
                                        obligatori={true}
                                        value={allergies}
																				error={errors.allergies}
                                        file={allergiesFile}
                                        handleChange={(e) => setAllergies(e.target.value)} />

                                      {allergies === "1" && <FormTextArea id="allergiesObservacions" name="allergiesObservacions" col="col-5" obligatori={true}  label="Observacions" value={allergiesObservacions} rows="5" cols="80" maxLength="1000" onChange={setAllergiesObservacions} error={errors.allergiesObservacions} />}
                                      { ( allergies === "1" && config.adjuntMedic ) && <DropZone id="allergiesFile" file={allergiesFile} onChange={setAllergiesFile} obligatori={config.adjuntMedicObligatori}  label="Adjuntar document" labelDrop="adjuntar document" error={errors.allergiesFile}   handleFileError={ (msg) =>handleFileError('allergiesFile',msg) } />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="malalties"
                                        name="malalties"
                                        col="col-3"
                                        label="Malalties greus"
                                        obligatori={true}
                                        value={malalties}
																				error={errors.malalties}
                                        file={malaltiesFile}
                                        handleChange={(e) => setMalalties(e.target.value)} />

                                      {malalties === "1" && <FormTextArea id="malaltiesObservacions" name="malaltiesObservacions" col="col-5" obligatori={true}  label="Observacions" value={malaltiesObservacions} rows="5" cols="80" maxLength="1000" onChange={setMalaltiesObservacions} error={errors.malaltiesObservacions} />}
                                      { ( malalties === "1" && config.adjuntMedic ) && <DropZone id="malaltiesFile" file={malaltiesFile} onChange={setMalaltiesFile} obligatori={config.adjuntMedicObligatori}  label="Adjuntar document" labelDrop="adjuntar document" error={errors.malaltiesFile}  handleFileError={ (msg) =>handleFileError('malaltiesFile',msg) }  />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="problemesVista"
                                        name="problemesVista"
                                        col="col-3"
                                        label="Problemes de vista/oïda?"
                                        obligatori={true}
                                        value={problemesVista}
																				error={errors.problemesVista}
                                        file={problemesVistaFile}
                                        handleChange={(e) => setProblemesVista(e.target.value)} />

                                      {problemesVista === "1" && <FormTextArea id="problemesVistaObservacions" name="problemesVistaObservacions" obligatori={true} col="col-5" label="Observacions" value={problemesVistaObservacions} rows="5" cols="80" maxLength="1000" onChange={setProblemesVistaObservacions} error={errors.problemesVistaObservacions} />}
                                      { ( problemesVista === "1" && config.adjuntMedic ) && <DropZone id="problemesVistaFile" file={problemesVistaFile} obligatori={config.adjuntMedicObligatori} onChange={setProblemesVistaFile} label="Adjuntar document" labelDrop="adjuntar document" error={errors.problemesVistaFile} handleFileError={ (msg) =>handleFileError('problemesVistaFile',msg) }  />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="problemesMobilitat"
                                        name="problemesMobilitat"
                                        col="col-3"
                                        label="Problemes de mobilitat?"
                                        obligatori={true}
                                        value={problemesMobilitat}
																				error={errors.problemesMobilitat}
                                        file={problemesMobilitatFile}
                                        handleChange={(e) => setProblemesMobilitat(e.target.value)} />

                                      {problemesMobilitat === "1" && <FormTextArea id="problemesMobilitatObservacions" name="problemesMobilitatObservacions" col="col-5" obligatori={true} label="Observacions" value={problemesMobilitatObservacions} rows="5" cols="80" maxLength="1000" onChange={setProblemesMobilitatObservacions} error={errors.problemesMobilitatObservacions} />}
                                      { (problemesMobilitat === "1" && config.adjuntMedic )  && <DropZone id="problemesMobilitatFile" file={problemesMobilitatFile} onChange={setProblemesMobilitatFile} obligatori={config.adjuntMedicObligatori}  label="Adjuntar document" labelDrop="adjuntar document" error={errors.problemesMobilitatFile}  handleFileError={ (msg) =>handleFileError('problemesMobilitatFile',msg) } />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="prensMedicacio"
                                        name="prensMedicacio"
                                        col="col-3"
                                        label="Prens alguna medicació?"
                                        obligatori={true}
                                        value={medicacio}
																				error={errors.medicacio}
                                        file={medicacioFile}
                                        handleChange={(e) => setMedicacio(e.target.value)} />

                                      {medicacio === "1" && <FormTextArea id="medicacioObservacions" name="medicacioObservacions" obligatori={true} col="col-5" label="Observacions" value={medicacioObservacions} rows="5" cols="80" maxLength="1000" onChange={setMedicacioObservacions} error={errors.medicacioObservacions} />}
                                      { (medicacio === "1" && config.adjuntMedic ) && <DropZone id="medicacioFile" file={medicacioFile} onChange={setMedicacioFile} obligatori={config.adjuntMedicObligatori}  label="Adjuntar document" labelDrop="adjuntar document" error={errors.medicacioFile}  handleFileError={ (msg) =>handleFileError('medicacioFile',msg) } />}

                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>


                    { ( allergies === "1" || malalties === "1" || problemesVista === "1" || problemesMobilitat === "1" || medicacio === "1" ) &&
                    (<div className="container">
                      <div className="row">
                        <div className="col col-12">
                          <div class="t-Form-labelContainer" style={{ float: 'none', textAlign: 'center' }}>
                            <label class="t-Form-label">
                              {config.textDadesMediquesAlgunSi}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>)}


                  <div className="container">
                    <div className="row">
                      <div className="col col-12" align="center">
                        <button onClick={validaMediques} className="button">SEGÜENT</button>
                      </div>
                    </div>
                  </div>

                </Dropdown>
              </div>
            </div>

          </div>
        </div>



      </div>)}


      {/* DADES LABORALS */}
      { dadesPersonals.validat && dadesMediques.validat && (
      <div className={ edat < 16 ? "container no-display" : "container si-display"}>
        <div className="row">
          <div className="col col-12 ">
            <div className="t-Region t-Region--hideShow is-expanded t-Region--scrollBody t-Form--noPadding margin-top-sm margin-bottom-none margin-left-none margin-right-none a-Collapsible">
              <div className="t-Region-header">
                <div className="t-Region-headerItems  t-Region-headerItems--controls">
                  <button className="t-Button t-Button--icon t-Button--hideShow" type="button" onClick={toggleDadesLaboralsOpen}>
                    {dadesLaboralsOpen && <FontAwesomeIcon icon={faChevronUp} fontSize="18" className="t-Icon" />}
                    {!dadesLaboralsOpen && <FontAwesomeIcon icon={faChevronDown} fontSize="18" className="t-Icon" />}
                  </button>
                </div>
                <div className="t-Region-headerItems t-Region-headerItems--title">
                  <h2 className="t-Region-title a-Collapsible-heading">Dades laborals</h2>
                </div>
                <div className="t-Region-headerItems t-Region-headerItems--buttons"></div>
              </div>
              <div className="t-Region-bodyWrap">

                <Dropdown open={dadesLaboralsOpen}>
                  <div className="t-Region-body a-Collapsible-content">

                    <div className="container">
                      <div className="row">
                        <div className="col col-12 ">
                          <div className="t-Region t-Region--noPadding t-Region--removeHeader t-Region--noBorder t-Region--scrollBody t-Form--noPadding t-Form--leftLabels margin-top-sm">
                            <div className="t-Region-bodyWrap">
                              <div className="t-Region-body">
                                <div className="container">
                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="treballant"
                                        name="treballant"
                                        col="col-2"
                                        label="Actualment estàs treballant?"
                                        obligatori={false}
                                        value={treballant}
                                        error={errors.treballant}
                                        handleChange={(e) => setTreballant(e.target.value)} />

                                { config.treballantFile && (<>
                                  { treballant === "1" && 
                                    (<DropZone id="treballantFile" file={treballantFile} onChange={setTreballantFile} 
                                    label="Adjuntar contacte treball*" 
                                    labelDrop="adjuntar imatge" 
                                    error={errors.treballantFile} 
                                    handleFileError={ (msg) =>handleFileError('treballantFile',msg) }  />
                                    ) 
                                  }
                                </>)}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="treballatMai"
                                        name="treballatMai"
                                        col="col-2"
                                        label="Has treballat mai?"
                                        obligatori={false}
                                        value={hasTreballatMai}
                                        error={errors.hasTreballatMai}
                                        handleChange={(e) => setHasTreballatMai(e.target.value)} />

                                      {hasTreballatMai === "1" && <FormTextArea id="hasTreballatMaiSector" name="hasTreballatMaiSector" col="col-5" label="En quin sector professional?" value={hasTreballatMaiSector} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setHasTreballatMaiSector} error={errors.hasTreballatMaiSector} />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="treballarasDurantCurs"
                                        name="treballarasDurantCurs"
                                        col="col-2"
                                        label="Treballaràs durant el curs?"
                                        obligatori={false}
                                        value={treballarasDurantCurs}
                                        error={errors.treballarasDurantCurs}
                                        handleChange={(e) => setTreballarasDurantCurs(e.target.value)} />

                                      {treballarasDurantCurs === "1" && <FormTextArea id="treballarasDurantCursHorari" name="treballarasDurantCursHorari" col="col-5" label="De què i en quin horar?" value={treballarasDurantCursHorari} obligatori={false} rows="5" cols="80" maxLength="1000" onChange={setTreballarasDurantCursHorari} error={errors.treballarasDurantCursHorari} />}

                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col col-12 ">

                                      <FormSelectYesNo id="creusTreballarDificultaEstudis"
                                        name="creusTreballarDificultaEstudis"
                                        col="col-2"
                                        label="Creus que treballar et dificultarà els teus estudis?"
                                        obligatori={false}
                                        value={creusTreballarDificultaEstudis}
                                        error={errors.creusTreballarDificultaEstudis}
                                        handleChange={(e) => setCreusTreballarDificultaEstudis(e.target.value)} />

                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="row">
                      <div className="col col-12" align="center">
                        <button onClick={validaLaborals} className="button">SEGÜENT</button>
                      </div>
                    </div>
                  </div>

                </Dropdown>
              </div>
            </div>

          </div>
        </div>
      </div>
        )}

    </div>
  );
}